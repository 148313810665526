import React, { useState, FC, useEffect } from 'react';
import styles from './Coupons.module.css';
import classNames from 'classnames';
import scissors from '../../assets/img/scissors.png';
import SwipeableViews from 'react-swipeable-views';
import { RootState, CouponsTypeState, CouponsState } from 'types/store';
import { useSelector, useDispatch } from 'react-redux';
import { CouponsLoader } from './CouponLoader';
import {
	fetchAvailableCoupons,
	fetchBookedCoupons,
	fetchExpiringCoupons,
} from 'store/coupons';
import { Layout } from 'views/common/Layout';
import { CouponsPageType } from 'types/coupon';
import { CouponQuery } from 'types/queries';
import { CouponSort } from './CouponSort';
import { fetchCurrencies } from 'store/currencies';
import { SnapFloatingButton } from 'views/Snap/SnapButton';
import { useRouter } from 'hooks/router';
import { useTranslation } from 'react-i18next';
import { Sticky, StickyContainer } from 'react-sticky';
import { CouponsErrorModal } from './ErrorModal';
import { EventsLogger } from 'views/common/EventsLogger';

type TabProps = {
	index: number;
	text: string;
	activeIndex: number;
	onClick: any;
};

const dispatchFunction = {
	0: fetchAvailableCoupons,
	1: fetchExpiringCoupons,
	2: fetchBookedCoupons,
};

const pageTypes = {
	0: CouponsPageType.AVAILABLE,
	1: CouponsPageType.EXPIRING,
	2: CouponsPageType.BOOKED,
};

const Tab: FC<TabProps> = ({ index, text, activeIndex, onClick }: TabProps) => {
	const { t } = useTranslation();
	return (
		<div
			className={classNames(styles.btn, {
				[styles.activeBtn]: activeIndex === index,
			})}
			onClick={() => onClick(index)}
		>
			{t(text)}
		</div>
	);
};

export const CouponsPage: FC = () => {
	const dispatch = useDispatch();
	const { history } = useRouter();
	const {
		initialized,
		available,
		booked,
		expiring,
	}: CouponsState = useSelector((store: RootState) => Object.assign({}, store.coupons));

	const { t } = useTranslation();
	const [activeTab, setActiveTab] = useState<number>(0);
	const changeTab = (tabIndex: number, tabName: string) => {
		setActiveTab(tabIndex);
		EventsLogger.tabClicked(tabName.toString());
	}

	const loadMore = (dispatchFunc: any, coupons: CouponsTypeState) => {
		const {
			pagination: { current, total },
		} = coupons;

		if (current >= total) {
			return;
		}

		dispatch(
			dispatchFunc(
				Object.assign({}, coupons.query, { page: coupons.pagination.current + 1 })
			)
		);
	};

	const refresh = (dispatchFunc: any, coupons: CouponsTypeState) => {
		dispatch(dispatchFunc(Object.assign({}, coupons.query, { page: 1 })));
	};

	const onSortChange = (sortQuery: Exclude<CouponQuery, 'page'>) => {
		const dispatchFunc = dispatchFunction[activeTab];
		dispatch(
			dispatchFunc({ page: 1, sort: sortQuery.sort, sortType: sortQuery.sortType })
		);
	};

	const getQuery = () => {
		switch (activeTab) {
			case 0:
				return available.query;
			case 1:
				return expiring.query;
			default:
				return booked.query;
		}
	};

	const showSort = () => {
		switch (activeTab) {
			case 0:
				return available.isLoading || available.coupons.length > 0;
			case 1:
				return expiring.isLoading || expiring.coupons.length > 1;
			default:
				return booked.isLoading || booked.coupons.length > 1;
		}
	};

	useEffect(() => {
		if (initialized) {
			return;
		}
		dispatch(fetchCurrencies());
		dispatch(fetchAvailableCoupons(available.query));
		dispatch(fetchExpiringCoupons(expiring.query));
		dispatch(fetchBookedCoupons(booked.query));
	}, [available.query, expiring.query, booked.query, initialized, dispatch]);

	return (
		<Layout
			title=""
			hideBackButton
			scrolling={false}
			header={
				<div className={styles.navigations}>
					<div
						className={classNames(styles.navigation, styles.activeNavigation)}
					>
						{t('coupon.coupon')}
					</div>
					<div
						className={styles.navigation}
						onClick={() => history.replace('./history')}
					>
						{t('History')}
					</div>
				</div>
			}
		>
			<div id={styles.couponsPage}>
				<CouponsErrorModal></CouponsErrorModal>
				<StickyContainer id="coupons-loader" className={styles.sticky}>
					{available.coupons.length > 0 ?
						<SnapFloatingButton />
						: ''}
					<div className={styles.header}>
						<div className={styles.headerBg}>
							<img src={scissors} alt="scissors" />
						</div>
					</div>
					<div className={styles.body}>
						<SwipeableViews
							animateHeight={!available.isLoading}
							enableMouseEvents
							index={activeTab}
							onChangeIndex={setActiveTab}
						>
							<CouponsLoader
								key="available_coupons"
								name="available_coupons"
								pageType={CouponsPageType.AVAILABLE}
								couponsState={available}
								loadMore={() =>
									loadMore(fetchAvailableCoupons, available)
								}
								refresh={() => refresh(fetchAvailableCoupons, available)}
							/>
							<CouponsLoader
								key="expiring_coupons"
								name="expiring_coupons"
								pageType={CouponsPageType.EXPIRING}
								couponsState={expiring}
								loadMore={() => loadMore(fetchExpiringCoupons, expiring)}
								refresh={() => refresh(fetchExpiringCoupons, expiring)}
							/>
							<CouponsLoader
								key="booked_coupons"
								name="booked_coupons"
								couponsState={booked}
								pageType={CouponsPageType.BOOKED}
								loadMore={() => loadMore(fetchBookedCoupons, booked)}
								refresh={() => refresh(fetchBookedCoupons, booked)}
							/>
						</SwipeableViews>
					</div>
				</StickyContainer>
			</div>
		</Layout>
	);
};
