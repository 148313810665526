export enum CouponsPageType {
	AVAILABLE = 'available',
	EXPIRING = 'expiring',
	BOOKED = 'booked',
}
export type ImageResource = {
	id: number;
	url: string;
};

export type Image = {
	thumbnail: ImageResource;
	original: ImageResource;
};

export type CouponBooking = {
	id: number;
	name: string;
	bookedAt: Date;
	expiresAt: Date;
	remainingQuantity: number;
};

export type CouponReward = {
	currency: number;
	value: number;
	sourceType: string;
	total: number;
};

export type Coupon = {
	id: number;
	name: string;
	reward: CouponReward;
	startDate: Date;
	endDate: Date;
	minimumItems: number;
	maxCouponsPerUser: number;
	remainingQuantity: number;
	images: Image[];
	smallImage: Image;
	largeImage: Image;
	booking?: CouponBooking;
	chains: [{ id: string; name: string }];
	featured: boolean;
	excludeChains: boolean;
	productDetails: string;
	howToEarn: string;
};
