import pkg from '../package.json';

export default {
	version: pkg.version,
	authUrl: process.env.REACT_APP_AUTH_URL,
	redirectUrl: process.env.REACT_APP_REDIRECT_URL,
	baseUrl: process.env.REACT_APP_BASE_URL,
	expiryDays: process.env.REACT_APP_EXPIRY_DAYS || 7,
	defaultLanguage: process.env.REACT_APP_DEFAULT_LANG || 'id',
	zendeskKey: process.env.REACT_APP_ZENDESK_KEY,
	imageScale: process.env.REACT_APP_IMAGE_SCALE || 1080,
	fireBaseConfig: JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG || ''),
	bugsnagKey: process.env.REACT_APP_BUGSNAG_KEY,
	stage: process.env.REACT_APP_STAGE,
	amplitudeKey: process.env.REACT_APP_AMPLITUDE_KEY,
};
