import axios, { AxiosRequestConfig } from 'axios';
import config from '../config';
import humps from 'humps';
import cookie from 'react-cookies';
import { Coupon } from 'types/coupon';
import { CouponsTypeState } from 'types/store';
import queryString from 'query-string';
import { CouponQuery, ReceiptsQuery } from 'types/queries';
import { Currency } from 'types/currency';
import { Receipt, PurchaseTag, PurchaseDecision } from 'types/receipt';
import jwt from 'jsonwebtoken';
import { EventsLogger } from 'views/common/EventsLogger';

const baseUrl = config.baseUrl;

const saveToken = token => {
	cookie.save('TOKEN', token);
};

const getToken = () => {
	return cookie.load('TOKEN');
};

export const saveLang = lang => {
	cookie.save('LANG', lang || 'id');
};

export const getLang = () => {
	return cookie.load('LANG');
};

const authInterceptor = async (request: AxiosRequestConfig) => {
	const isAnonymous = request.url?.includes(config.authUrl || '/auth');
	if (isAnonymous) {
		return request;
	}
	request.headers['Authorization'] = `Bearer ${getToken()}`;
	request.headers['Accept-Language'] = getLang();
	return request;
};

// const proxy = async (request: AxiosRequestConfig) => {
// 	request.url = 'https://cors-anywhere.herokuapp.com/' + request.url;
// 	return request;
// };

axios.interceptors.request.use(authInterceptor);
//axios.interceptors.request.use(proxy);
const BookingToCoupon = booking => {
	let { coupon } = booking;
	coupon.booking = {
		id: booking.id,
		bookedAt: booking.bookedAt,
		expiresAt: booking.expiresAt,
		remainingQuantity: booking.remainingQuantity,
	};
	return coupon;
};

const camelizeKeys = response => {
	return humps.camelizeKeys(response.data);
};

const camelizeKeysForReceipt = response => {
	return humps.camelizeKeys(response.data.receipt);
};

export const exchangeToken = async (
	partnerName: string,
	partnerToken: string,
	express: boolean
) => {
	let data: { sub: string, cpn?: number } = {
		sub: '',
	};

	try {
		data = jwt.decode(partnerToken);
		console.log('partnerToken', partnerToken, data);
		EventsLogger.setUser(partnerName, express, data.sub);
		EventsLogger.login(partnerName, express, data.sub);
	} catch (error) {
		EventsLogger.setUser(partnerName, express, 'unkown');
		EventsLogger.exception('Failed to decode token', { error: error });
		console.log('decode error partnerToken', partnerToken, error);
	}

	const result = axios.post(`${config.authUrl}/${partnerName}`, partnerToken, {
		headers: { 'Content-Type': 'text/plain', 'Access-Control-Allow-Origin': '*' },
		responseType: 'json',
	});

	return result.then(
		response => {
			const auth = { ...response.data, data };
			if (response.data.cpn) {
				EventsLogger.bookCouponExpress(response.data.cpn);
			}
			saveToken(auth.token);
			return humps.camelizeKeys(auth);
		},
		error => {
			console.log('auth error', partnerToken, error);
			return Promise.reject({
				error,
				data,
			});
		}
	);
};

export const getCoupons = async (
	query: CouponQuery
): Promise<Exclude<CouponsTypeState, 'isLoading'>> => {
	const querystring = humps.decamelizeKeys(query);
	const result = axios.get(
		`${baseUrl}/user_external/coupons?${queryString.stringify(querystring)}`
	);

	return result.then(response => {
		const { pagination, coupons } = humps.camelizeKeys(response.data);
		return {
			isLoading: false,
			pagination,
			coupons: coupons,
			error: null,
			query: Object.assign({}, query),
		};
	});
};

export const getCoupon = async (id: number): Promise<Coupon> => {
	const result = await axios.get(`${baseUrl}/user_external/coupons/${id}`);
	return humps.camelizeKeys(result.data);
};

export const getBookedCoupons = async (
	query: CouponQuery
): Promise<Exclude<CouponsTypeState, 'isLoading'>> => {
	const querystring = humps.decamelizeKeys(query);
	const result = axios.get(
		`${baseUrl}/user_external/me/coupons?${queryString.stringify(querystring)}`
	);
	return result.then(response => {
		const { pagination, bookings } = humps.camelizeKeys(response.data);
		return {
			isLoading: false,
			pagination,
			error: null,
			coupons: bookings.map(BookingToCoupon),
			query: Object.assign({}, query),
		};
	});
};

export const bookCoupon = async (id: number): Promise<Coupon> => {
	const result = axios.post(`${baseUrl}/user_external/me/coupons`, { coupon: id });
	return result.then(response => {
		const booking = humps.camelizeKeys(response.data);
		return BookingToCoupon(booking);
	});
};

export const getCurrencies = async (): Promise<Currency[]> => {
	const result = axios.get(`${baseUrl}/user_external/utils/currencies/all`);
	return result.then(camelizeKeys);
};

export const createReceipt = async (): Promise<Receipt> => {
	const result = axios.post(`${baseUrl}/user_external/receipts`);
	return result.then(camelizeKeysForReceipt);
};

export const commitReceipt = async (id: number): Promise<Receipt> => {
	const result = axios.patch(`${baseUrl}/user_external/receipts/${id}/commit`);
	return result.then(camelizeKeysForReceipt);
};

export const editReceipt = async (
	id: number,
	tag: PurchaseTag,
	decision: PurchaseDecision
): Promise<Receipt> => {
	const result = axios.put(`${baseUrl}/user_external/receipts/${id}`, {
		purchase_decision_by: decision,
		purchase_tag: tag,
	});
	return result.then(camelizeKeysForReceipt);
};

export const uploadReceiptImage = async (
	receiptId: number,
	imageUrl: string
): Promise<Receipt> => {
	const image = await fetch(imageUrl).then(r => r.blob());
	const formData = new FormData();
	formData.append('image', image);

	const result = axios.post(
		`${baseUrl}/user_external/receipts/${receiptId}/images`,
		formData
	);
	return await result.then(camelizeKeysForReceipt);
};

export const uploadReceiptImages = async (
	receiptId: number,
	images: any[]
): Promise<Receipt> => {
	let response;

	for (let i = 0; i < images.length; i++) {
		response = await uploadReceiptImage(receiptId, images[i]);
	}

	return response;
};

export const fetchReceipts = async (query: ReceiptsQuery) => {
	query = Object.assign({}, query);
	if (query.status) {
		query['filters[status]'] = query.status;
	}
	const querystring = humps.decamelizeKeys(query);
	const result = axios.get(
		`${baseUrl}/user_external/receipts?${queryString.stringify(querystring)}`
	);
	return result.then(response => {
		const { pagination, receipts, statistics } = humps.camelizeKeys(response.data);
		return {
			receipts: {
				isLoading: false,
				pagination,
				receipts: receipts,
				query: Object.assign({}, query),
			},
			statistics,
		};
	});
};

export const getReceipt = async (id: number | string): Promise<Receipt> => {
	const receiptPromise = axios.get(`${baseUrl}/user_external/receipts/${id}`);
	const couponsPromise = axios.get(`${baseUrl}/user_external/receipts/${id}/coupons`);

	try {
		const receipt: Receipt = humps.camelizeKeys((await receiptPromise).data).receipt;
		const coupons: Coupon[] = humps.camelizeKeys((await couponsPromise).data).coupons;
		receipt.coupons = coupons;
		return receipt;
	} catch (error) {
		return Promise.reject(error);
	}
};
