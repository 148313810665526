import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import authReducer from './auth';
import couponsReducer from './coupons';
import currenciesReducer from './currencies';
import receiptsReducer from './receipts';

const reducer = combineReducers({
	auth: authReducer,
	coupons: couponsReducer,
	currencies: currenciesReducer,
	receipts: receiptsReducer,
});

const store = configureStore({
	reducer,
	middleware: getDefaultMiddleware({
		serializableCheck: {
			// Ignore these action types
			ignoredActions: [
				'receipts/createReceiptSuccess',
				'coupons/availableCouponsFetch',
				'coupons/expiringCouponsFetch',
			],
		},
	}),
});

export default store;
