import React, { FC, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'types/store';
import styles from './Snap.module.css';
import classNames from 'classnames';
import { Plus } from 'react-feather';
import { Camera } from './Camera';
import {
	replaceReceiptImageAction,
	uploadReceiptImageAction,
	clearReceiptError,
} from 'store/receipts';
import { ReceiptSubmit } from './ReceiptSubmit';
import { useRouter } from 'hooks/router';
import Loader from 'views/common/loader/Loader';
import { useTranslation } from 'react-i18next';
import { ErrorModal } from 'views/common/ErrorModal';

export const Snap: FC = () => {
	const { t } = useTranslation();
	const { history } = useRouter();
	const dispatch = useDispatch();
	const [isSubmiting, setSubmitting] = useState<boolean>(false);
	const images = useSelector((store: RootState) => store.receipts.images);
	const receiptLoading = useSelector((store: RootState) => store.receipts.loading);
	const { error, errorMessage, errorTitle, errorCode } = useSelector(
		(store: RootState) => store.receipts
	);
	const [cameraLoading, setCameraLoading] = useState<boolean>(false);
	const sent = useSelector((store: RootState) => store.receipts.sent);
	const currentImage = images[images.length - 1];
	const previousImage = images[images.length - 2];

	useEffect(() => {
		if (!error && !receiptLoading && !images?.length && !sent) {
			history.goBack();
		}
	}, [receiptLoading, images, history, sent, error]);

	const clearErrors = () => {
		dispatch(clearReceiptError());
	};

	if (error) {
		return (
			<ErrorModal
				errorCode={errorCode}
				visible={error}
				title={errorTitle as string}
				message={errorMessage as string}
				onClose={clearErrors}
			></ErrorModal>
		);
	}

	if (isSubmiting) {
		return <ReceiptSubmit />;
	}

	if (receiptLoading || cameraLoading) {
		return <Loader></Loader>;
	}

	if (!images?.length) {
		return <React.Fragment></React.Fragment>;
	}

	const isMultiple = images.length > 1;

	const onImagesUpload = newImage => {
		dispatch(uploadReceiptImageAction(newImage));
		setCameraLoading(false);
	};
	const replaceImage = newImage => {
		dispatch(replaceReceiptImageAction(newImage));
		setCameraLoading(false);
	};
	const finalize = () => {
		setSubmitting(true);
	};

	return (
		<div className={styles.snap}>
			<div
				className={classNames(styles.receipts, {
					[styles.multiple]: isMultiple,
				})}
			>
				{isMultiple && (
					<div data-index={images.length - 1}>
						<img src={previousImage} alt="receipt" />
					</div>
				)}
				{currentImage && (
					<div data-index={images.length}>
						<img src={currentImage} alt="receipt" />
					</div>
				)}
			</div>
			<div className={styles.actions}>
				<Camera
					isLoading={() => setCameraLoading(true)}
					isActive={true}
					onChange={replaceImage}
				>
					<div>{t('RETAKE')}</div>
				</Camera>
				<Camera
					isLoading={() => setCameraLoading(true)}
					isActive={true}
					onChange={onImagesUpload}
				>
					<Plus className={styles.plus} />
				</Camera>
				<div onClick={finalize}>{t('FINISH')}</div>
			</div>
		</div>
	);
};
