import React, { FC, useState, useEffect } from 'react';
import styles from './Receipt.module.css';
import { Layout } from '../common/Layout';
import { useRouter } from 'hooks/router';
import {Receipt as ReceiptType, PurchaseTag, RewardSourceType} from 'types/receipt';
import { getReceipt } from 'api';
import Loader from 'views/common/loader/Loader';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Currency } from 'types/currency';
import { RootState } from 'types/store';
import filter from 'lodash/filter';
import groupBy from 'lodash/groupBy';
import sumBy from 'lodash/sumBy';
import find from 'lodash/find';
import map from 'lodash/map';
import isNil from 'lodash/isNil';
import ImageGallery from 'react-image-gallery';
import receiptSample from '../../assets/img/receipt_sample.jpg';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';
import 'react-image-lightbox/style.css';
import {formatCurrency, normalizeReceiptStatus} from 'utils';
import { Coupon, CouponReward } from 'types/coupon';
import { ZendeskAPI } from 'react-zendesk';
import { ErrorModal } from 'views/common/ErrorModal';
import { EventsLogger, CustomEvent } from 'views/common/EventsLogger';

const purchaseTagNames = {
	[PurchaseTag.SUPERMARKET]: 'supermarket_minimarket_pharmacies',
	[PurchaseTag.RESTAURANTS]: 'restaurants',
	[PurchaseTag.CLOTHING]: 'apparel_clothing',
	[PurchaseTag.TRANSPORTATION]: 'petrol_transportation',
	[PurchaseTag.ELECTRONICS]: 'electronics',
	[PurchaseTag.OTHERS]: 'others',
	[PurchaseTag.ECOMMERCE]: 'ecommerce',
	[PurchaseTag.UNKOWN]: 'unknown',
};

type ReceiptTagProps = {
	title: string;
	value: string | number | Date;
	visible?: boolean;
};
const ReceiptTag: FC<ReceiptTagProps> = ({ title, value, visible }: ReceiptTagProps) => {
	const { t } = useTranslation();
	if (!isNil(visible) && !visible) {
		return <React.Fragment></React.Fragment>;
	}
	return (
		<div className={styles.tag}>
			<div className={styles.tagValue}>{t(value as string)}</div>
			<div className={styles.tagName}>{t(title)}</div>
		</div>
	);
};

const capitalize = (value: string) => {
	if (!value) {
		return '';
	}
	return value.charAt(0).toUpperCase() + value.slice(1);
};

export const Receipt: FC = () => {
	const currencies = useSelector(
		(store: RootState): Currency[] => store.currencies.currencies
	);

	const { history } = useRouter();
	const { addToast } = useToasts();
	const { t } = useTranslation();
	const { query } = useRouter();
	const id = parseInt((query as { id: string }).id);

	const [receipt, setReceipt] = useState<ReceiptType>({} as ReceiptType);
	const [rewards, setRewards] = useState<CouponReward[]>([]);

	const [isCompleted, setIsCompleted] = useState<boolean>(false);
	const images = receipt.images?.map(i => {
		return { original: i.medium.url };
	});
	const [isLoading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<boolean>(false);
	const [errorCode, setErrorCode] = useState<string>('');

	useEffect(() => {
		EventsLogger.logCustomEvent(CustomEvent.VIEWED_RECEIPT);
		getReceipt(id).then(
			(receipt: ReceiptType) => {
				setReceipt(receipt);
				setIsCompleted(receipt.status === 'completed');
				const couponRewards = filter(receipt.totalRewards, {
					sourceType: RewardSourceType.TYPE_COUPON,
				});
				setRewards(couponRewards);
				setLoading(false);
			},
			e => {
				setError(true);
				setErrorCode(e?.response?.status);
				EventsLogger.exception('Failed to fetch receipt', { error: e.response });
			}
		);
	}, [id, addToast, history]);

	const onErrorClose = () => {
		setError(false);
		history.goBack();
	};

	if (error) {
		return (
			<ErrorModal
				errorCode={errorCode}
				visible={error}
				title="errors.receipt.title"
				message="errors.receipt.message"
				onClose={onErrorClose}
			></ErrorModal>
		);
	}

	if (isLoading) {
		return <Loader></Loader>;
	}

	const renderTotalRewards = () => {
		if (rewards?.length < 1) {
			return <React.Fragment></React.Fragment>;
		}

		const groupedRewards = groupBy(rewards, 'currency');
		const rewardsValue = Object.values(groupedRewards)
			.map((rs: any) => {
				const currency = find(currencies, { id: rs[0]?.currency });
				const currencyName = currency?.name;
				const sum = formatCurrency(currency, sumBy(rs, 'total'));
				return `${currencyName} ${sum}`;
			})
			.join(', ');

		return <ReceiptTag title={'Coupon Redeemed Value'} value={rewardsValue} />;
	};

	const renderCouponsNames = (coupons: Coupon[]) => {
		if (coupons?.length < 1) {
			return <React.Fragment></React.Fragment>;
		}

		const couponNames = map(coupons, 'name').join(', ');
		return <ReceiptTag title={'Coupon(s) Redeemed'} value={couponNames} />;
	};

	const renderRwardsTags = () => {
		return rewards.map(reward => {
			const currency = find(currencies, { id: reward.currency });
			return (
				<ReceiptTag
					key={`${reward.currency}-${reward.total}`}
					title={currency?.name}
					value={formatCurrency(currency, reward.total)}
				/>
			);
		});
	};

	return (
		<Layout title={`Receipt ID: ${receipt?.id}`}>
			<div className={styles.receiptPage}>
				<div className={styles.image}>
					{receipt.images?.length > 0 && (
						<ImageGallery
							showPlayButton={false}
							useBrowserFullscreen={false}
							showThumbnails={false}
							items={images}
							showFullscreenButton={true}
							showBullets={true}
						/>
					)}
					{receipt.images?.length < 1 && (
						<img src={receiptSample} alt="Receipt" />
					)}
				</div>
				<div className={styles.body}>
					<h3>{t('coupon.details.details')}</h3>
					<div className="tags">
						<ReceiptTag title="Status" value={capitalize(normalizeReceiptStatus(receipt))} />
						<ReceiptTag title="Type" value={capitalize(receipt.type)} />
						<ReceiptTag
							title="Date"
							value={moment(receipt.sentAt).format('DD MMM YYYY')}
						/>
						<ReceiptTag
							title="Store Name"
							value={capitalize(receipt.branch?.name)}
							visible={!isNil(receipt.branch?.name)}
						/>
						<ReceiptTag
							title="Tag"
							value={purchaseTagNames[receipt.purchaseTag]}
						/>
						<ReceiptTag
							title="Purchase time"
							value={moment(receipt.purchaseTime).format('DD MMM YYYY')}
						/>
						<ReceiptTag
							title="Reason"
							value={receipt.rejectReason}
							visible={!isNil(receipt.rejectReason)}
						/>
						<ReceiptTag
							title="Total value"
							value={formatCurrency(null, receipt.totalValue)}
							visible={isCompleted}
						/>
						{isCompleted && renderCouponsNames(receipt.coupons)}
						{isCompleted && renderTotalRewards()}
					</div>

					{isCompleted && (
						<React.Fragment>
							<h3>{t('Rewards')}</h3>
							<div className="tags">{renderRwardsTags()}</div>
						</React.Fragment>
					)}
					<div className={styles.action}>
						<div
							className={styles.btn}
							onClick={() => {
								EventsLogger.logCustomEvent(CustomEvent.CUSTOMER_SUPPORT);
								ZendeskAPI('webWidget', 'helpCenter:setSuggestions', {
									labels: ['receipt_sdk', 'coupon_sdk', 'legal_sdk'],
								});
								ZendeskAPI('webWidget', 'show');
								ZendeskAPI('webWidget', 'open');
							}}
						>
							{t('I need help with this receipt')}
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};
