import React, { FC, ReactElement } from 'react';
import styles from './Camera.module.css';
import classNames from 'classnames';
import loadImage from 'blueimp-load-image';
import config from '../../config';

export type CameraProps = {
	children: ReactElement;
	className?: string;
	isActive: boolean;
	onChange: any;
	isLoading: any;
};

export const Camera: FC<CameraProps> = ({
	children,
	className,
	isActive,
	onChange,
	isLoading,
}: CameraProps) => {
	const filesChanged = async e => {
		if (e.target.files.length < 1) {
			console.error('No files');
			return;
		}

		if (isLoading) {
			isLoading();
		}

		const image = e.target.files[0];
		let data = await loadImage(image, {
			noRevoke: true,
			orientation: true,
			canvas: true,
			meta: true,
			maxWidth: config.imageScale,
		});

		onChange(data.image.toDataURL());
	};

	return (
		<div className={classNames(className, styles.fileContainer)}>
			{children}
			{isActive && (
				<input
					onChange={filesChanged}
					className={styles.file}
					type="file"
					accept="image/*;capture=camera"
					id="file-input"
				/>
			)}
		</div>
	);
};
