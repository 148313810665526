import {Currency} from 'types/currency';
import {Receipt, RewardSourceType} from "./types/receipt";

export const formatCurrency = (currency: Currency | null, value: number) => {
	if (!value) {
		return value;
	}
	return value
		.toFixed(currency?.fractionDigits)
		.toString()
		.replace('.', currency?.decimalSeparator || ',')
		.replace(',', currency?.decimalSeparator || ',')
		.replace(/\B(?=(\d{3})+(?!\d))/g, currency?.groupSeparator || '.');
};

export const normalizeReceiptStatus = (receipt: Receipt): string => {
	return receipt.totalRewards.length == 0
		? receipt.status
		: receipt
			.totalRewards
			.map(reward => reward.sourceType)
			.filter(sourceType => sourceType == RewardSourceType.TYPE_COUPON)
			.length == 0
			? 'completed_no_coupons'
			: receipt.status;
};