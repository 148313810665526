import findIndex from 'lodash/findIndex';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getCoupons, bookCoupon as bookCouponApi, getBookedCoupons } from '../api/index';
import { Coupon, CouponsPageType } from '../types/coupon';
import { CouponsState, CouponsTypeState } from 'types/store';
import { CouponQuery } from 'types/queries';
import moment from 'moment';
import { CouponsSort, SortType, BookinsSort } from 'types/sort';
import { EventsLogger } from 'views/common/EventsLogger';

const couponsInitialState: CouponsTypeState = {
	coupons: [],
	error: null,
	isLoading: true,
	pagination: { current: 1, total: 1 },
	query: { page: 1, sort: CouponsSort.NAME, sortType: SortType.ASC },
};

const initialState: CouponsState = {
	[CouponsPageType.AVAILABLE]: Object.assign({}, couponsInitialState, {
		query: { page: 1, sort: CouponsSort.START_DATE, sortType: SortType.DESC },
	}),
	[CouponsPageType.EXPIRING]: Object.assign({}, couponsInitialState, {
		query: { page: 1, sort: CouponsSort.END_DATE, sortType: SortType.DESC },
	}),
	[CouponsPageType.BOOKED]: Object.assign({}, couponsInitialState, {
		query: { page: 1, sort: BookinsSort.BOOKING_DATE, sortType: SortType.DESC },
	}),
	initialized: false,
	hasError: false,
	errorCode: '',
	bookings: {},
};

const fetchCoupons = (state, query, type) => {
	if (query.page === 1) {
		state[type].coupons.length = 0;
	}

	state[type].query = query;
	state[type].isLoading = true;
};

const bookingCouponSuccess = (coupons, coupon, forceAdd = false) => {
	const couponIndex = findIndex(coupons, { id: coupon.id });
	if (couponIndex > -1) {
		coupons[couponIndex] = coupon;
	} else if (forceAdd) {
		coupons.push(coupon);
	}
};

const slice = createSlice({
	name: 'coupons',
	initialState: initialState,
	reducers: {
		reset: state => {
			state[CouponsPageType.AVAILABLE] = Object.assign({}, couponsInitialState, {
				query: { page: 1, sort: CouponsSort.START_DATE, sortType: SortType.DESC },
			});
			state[CouponsPageType.EXPIRING] = Object.assign({}, couponsInitialState, {
				query: { page: 1, sort: CouponsSort.END_DATE, sortType: SortType.DESC },
			});
			state[CouponsPageType.BOOKED] = Object.assign({}, couponsInitialState, {
				query: {
					page: 1,
					sort: BookinsSort.BOOKING_DATE,
					sortType: SortType.DESC,
				},
			});
			state.initialized = false;
			state.hasError = false;
			state.errorCode = '';
			state.bookings = {};
		},
		couponsFetch: (state, { payload: { query, type } }) => {
			fetchCoupons(state, query, type);
		},
		fetchCouponsSuccess: (state, { payload: { coupons, pagination, type } }) => {
			state.initialized = true;
			state[type].coupons = state[type].coupons.concat(coupons);
			state[type].pagination = pagination;
			state[type].isLoading = false;
		},
		bookingInProgress: (state, { payload: { id } }) => {
			state.bookings[(id as unknown) as string] = {
				loading: true,
				error: false,
			};
		},
		bookingSuccess: (state, action: PayloadAction<Coupon>) => {
			bookingCouponSuccess(state.available.coupons, action.payload);
			bookingCouponSuccess(state.booked.coupons, action.payload, true);
			bookingCouponSuccess(state.expiring.coupons, action.payload);
			state.bookings[action.payload.id] = null;
			delete state.bookings[action.payload.id];
		},
		bookingFailed: (state, { payload: { id, errorCode } }) => {
			state.bookings[(id as unknown) as string] = {
				loading: false,
				error: true,
				errorCode: errorCode,
			};
		},
		clearBookingErrorAction: (state, { payload: id }) => {
			state.bookings[(id as unknown) as string] = null;
			delete state.bookings[(id as unknown) as string];
		},
		fetchCouponsFailed: (state, { payload: { type, errorCode } }) => {
			state.hasError = true;
			state.errorCode = errorCode;
			state[type].isLoading = false;
		},
		clearCouponsErrorAction: state => {
			state.hasError = false;
		},
	},
});

export default slice.reducer;

// Actions
const {
	couponsFetch,
	fetchCouponsSuccess,
	bookingSuccess,
	fetchCouponsFailed,
	clearCouponsErrorAction,
	clearBookingErrorAction,
	bookingFailed,
	bookingInProgress,
	reset,
} = slice.actions;

export const resetCoupons = reset;
export const clearBookingError = clearBookingErrorAction;
export const clearCouponsError = clearCouponsErrorAction;
export const fetchAvailableCoupons = (query: CouponQuery) => async dispatch => {
	try {
		dispatch(couponsFetch({ query, type: CouponsPageType.AVAILABLE }));
		const data = await getCoupons(query);
		dispatch(fetchCouponsSuccess({ ...data, type: CouponsPageType.AVAILABLE }));
	} catch (e) {
		EventsLogger.exception(`Failed to fetch available coupons`, {
			error: e.response,
		});
		dispatch(
			fetchCouponsFailed({
				type: CouponsPageType.AVAILABLE,
				errorCode: e.response.status,
			})
		);
		return console.error(e.message);
	}
};

export const fetchExpiringCoupons = (query: CouponQuery) => async dispatch => {
	try {
		dispatch(couponsFetch({ query, type: CouponsPageType.EXPIRING }));
		query = Object.assign({}, query);
		query['filters[expiring_before]'] = moment()
			.subtract(6, 'days')
			.format();
		const data = await getCoupons(query);
		dispatch(fetchCouponsSuccess({ ...data, type: CouponsPageType.EXPIRING }));
	} catch (e) {
		EventsLogger.exception(`Failed to fetch expiring coupons`, { error: e.response });
		dispatch(
			fetchCouponsFailed({
				type: CouponsPageType.EXPIRING,
				errorCode: e.response.status,
			})
		);
		return console.error(e.message);
	}
};

export const fetchBookedCoupons = (query: CouponQuery) => async dispatch => {
	try {
		dispatch(couponsFetch({ query, type: CouponsPageType.BOOKED }));
		const data = await getBookedCoupons(query);
		dispatch(fetchCouponsSuccess({ ...data, type: CouponsPageType.BOOKED }));
	} catch (e) {
		EventsLogger.exception(`Failed to fetch booked coupons`, { error: e.response });
		dispatch(
			fetchCouponsFailed({
				type: CouponsPageType.BOOKED,
				errorCode: e.response.status,
			})
		);
		return console.error(e.message);
	}
};

export const bookCoupon = (id: number, coupon: Coupon) => async dispatch => {
	try {
		dispatch(bookingInProgress({ id }));
		const data = await bookCouponApi(id);
		EventsLogger.bookCoupon(coupon);
		dispatch(bookingSuccess(data));
	} catch (e) {
		EventsLogger.exception(`Failed to book coupon`, { error: e.response });
		dispatch(bookingFailed({ id, errorCode: e.response.status }));
		return console.error(e.message);
	}
};
