import React, { FC, useEffect, useState } from 'react';
import styles from './Receipts.module.css';
import { Layout } from 'views/common/Layout';
import classNames from 'classnames';
import { useRouter } from 'hooks/router';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, ReceiptTypeState } from 'types/store';
import {
	initializeReceipts,
	fetchCompleted,
	fetchLatest,
	fetchRejected,
	fetchSent,
	clearReceiptError,
} from 'store/receipts';
import { ReceiptLoader } from './ReceiptLoader';
import { ReceiptStatus } from 'types/receipt';
import Loader from 'views/common/loader/Loader';
import { useTranslation } from 'react-i18next';
import { ErrorModal } from 'views/common/ErrorModal';

export const Receipts: FC = () => {
	const { history } = useRouter();
	const {
		error,
		errorMessage,
		errorTitle,
		errorCode,
		pages: { completed, latest, rejected, sent, statistics },
	} = useSelector((store: RootState) => Object.assign({}, store.receipts));

	const dispatch = useDispatch();
	const [status, setStatus] = useState<ReceiptStatus | null>(null);
	const { t } = useTranslation();

	useEffect(() => {
		dispatch(initializeReceipts());
	}, [dispatch]);

	const loadMore = (state: ReceiptTypeState, dispatchFunc: any) => {
		if (state.pagination.current >= state.pagination.total) {
			return;
		}

		const query = Object.assign({}, state.query, {
			page: state.pagination.current + 1,
		});

		dispatch(dispatchFunc(query));
	};

	const onClose = () => {
		dispatch(clearReceiptError());
	};

	const isLoading =
		completed.isLoading || latest.isLoading || rejected.isLoading || sent.isLoading;

	return (
		<Layout
			title=""
			hideBackButton
			header={
				<div className={styles.navigations}>
					<div
						className={styles.navigation}
						onClick={() => history.replace('./coupons')}
					>
						{t('coupon.coupon')}
					</div>
					<div
						className={classNames(styles.navigation, styles.activeNavigation)}
					>
						{t('History')}
					</div>
				</div>
			}
		>
			<div id={styles.receiptsPage}>
				<ErrorModal
					errorCode={errorCode}
					onClose={onClose}
					visible={error}
					message={errorMessage as string}
					title={errorTitle as string}
				></ErrorModal>
				<div className={styles.body}>
					<div className={styles.btns}>
						<div
							className={styles.btn}
							onClick={() => {
								if (statistics.sent < 1) return;
								setStatus(ReceiptStatus.SENT);
							}}
						>
							<p>{t('Sent')}</p>
							<div className={styles.content}> {statistics.sent}</div>
						</div>
						<div
							className={styles.btn}
							onClick={() => {
								if (statistics.rejected < 1) return;
								setStatus(ReceiptStatus.REJECTED);
							}}
						>
							<p>{t('Rejected')}</p>
							<div className={styles.content}>{statistics.rejected}</div>
						</div>
						<div
							className={styles.btn}
							onClick={() => {
								if (statistics.completed < 1) return;
								setStatus(ReceiptStatus.COMPLETED);
							}}
						>
							<p>{t('Completed')}</p>
							<div className={styles.content}>{statistics.completed}</div>
						</div>
					</div>
					{!status && (
						<ReceiptLoader
							title="Latest"
							loadMore={() => loadMore(latest, fetchLatest)}
							receipts={latest.receipts}
						/>
					)}

					{status === ReceiptStatus.COMPLETED && (
						<ReceiptLoader
							title="Completed"
							loadMore={() => loadMore(completed, fetchCompleted)}
							receipts={completed.receipts}
						/>
					)}

					{status === ReceiptStatus.REJECTED && (
						<ReceiptLoader
							title="Rejected"
							loadMore={() => loadMore(rejected, fetchRejected)}
							receipts={rejected.receipts}
						/>
					)}

					{status === ReceiptStatus.SENT && (
						<ReceiptLoader
							title="Sent"
							receipts={sent.receipts}
							loadMore={() => loadMore(sent, fetchSent)}
						/>
					)}

					{isLoading && (
						<div className={styles.loading}>
							<Loader></Loader>
						</div>
					)}
				</div>
			</div>
		</Layout>
	);
};
