import React, { FC } from 'react';
import { Tip } from './Tip';
import styles from './ReceiptTip.module.css';
import receipt from '../../assets/img/long_receipt.png';
import mobile from '../../assets/img/mobile_plus.png';
import { Plus } from 'react-feather';

export const ReceiptTip: FC<{ isActive: boolean }> = ({
	isActive,
}: {
	isActive: boolean;
}) => {
	return (
		<Tip primaryTip="tips.receipt.primaryTip" secondaryTip="tips.receipt.secondTip">
			<div className={styles.images}>
				{isActive && (
					<img className={styles.receipt} src={receipt} alt="receipt" />
				)}
				{isActive && <img className={styles.mobile} src={mobile} alt="mobile" />}
				{isActive && <Plus color="#ffffff" size={30} className={styles.plus} />}
			</div>
		</Tip>
	);
};
