import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import config from 'config';

export const bugsnag = Bugsnag.start({
	apiKey: config.bugsnagKey as string,
	appVersion: config.version,
	releaseStage: config.stage,
	maxBreadcrumbs: 100,
	enabledErrorTypes: {
		unhandledExceptions: true,
		unhandledRejections: true,
	},
	plugins: [new BugsnagPluginReact()],
});
