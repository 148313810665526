import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Tip.module.css';

export type TipProps = {
	children: ReactElement;
	primaryTip: string;
	secondaryTip: string;
	thirdTip?: string;
};

export const Tip: FC<TipProps> = ({
	children,
	primaryTip,
	secondaryTip,
	thirdTip,
}: TipProps) => {
	const { t } = useTranslation();

	return (
		<div className={styles.tip}>
			<div className={styles.imageContainer}>{children}</div>
			<div className={styles.tips}>
				<h3 className={styles.primaryTip}>{t(primaryTip)}</h3>
				<p className={styles.secondaryTip}>{t(secondaryTip)}</p>
				{thirdTip && <p className={styles.secondaryTip}>{t(thirdTip)}</p>}
			</div>
		</div>
	);
};
