import React, { FC } from 'react';
import styles from './SnapButton.module.css';
import imageSnap from '../../assets/img/camera_snap.png';
import { useRouter } from 'hooks/router';

export const SnapFloatingButton: FC = () => {
	const router = useRouter();

	return (
		<div
			className={styles.floatingButton}
			onClick={() => router.history.push('/snap')}
		>
			<img src={imageSnap} alt="camera" />
		</div>
	);
};
