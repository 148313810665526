import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'types/store';
import { CheckCircle, XCircle } from 'react-feather';
import classNames from 'classnames';
import styles from './Submitted.module.css';
import { useRouter } from 'hooks/router';

const failureTitle = 'Sorry, your receipt was not submitted';

export const Submitted: FC = () => {
	const { history } = useRouter();
	const isExpress = useSelector((store: RootState) => store.auth.express);
	const errorCode = useSelector((store: RootState) => store.receipts.errorCode);
	const instantBookingError = useSelector((store: RootState) => store.auth.errorCode);
	const isGojekExpress = useSelector(
		(store: RootState) => store.auth.isGoJek && store.auth.express
	);
	const isInstantBookingError = instantBookingError?.length > 0;
	const isError =
		useSelector((store: RootState) => store.receipts.submitError) ||
		isInstantBookingError;
	const { t } = useTranslation();

	const getTitle = () => {
		if (isInstantBookingError) {
			return t(`express.${instantBookingError}`);
		}

		if (isError) {
			return t(failureTitle);
		}

		if (isGojekExpress) {
			return t('snap.submit.success.gojek.title');
		}

		return t('snap.submit.success.title');
	};

	const getMessage = () => {
		if (isError) {
			return `Error: (${errorCode || 'IB-404'})`;
		}

		if (isGojekExpress) {
			return t('snap.submit.success.gojek.message');
		}

		return t('snap.submit.success.message');
	};

	return (
		<div
			className={classNames(styles.submittedPage, {
				[styles.error]: isError,
			})}
		>
			{isInstantBookingError && <div className={styles.title}>{t('sorry')}</div>}
			<div className={styles.title}>{getTitle()}</div>
			<div className={styles.message}>{getMessage()}</div>
			<div className={styles.icon}>
				{isError ? (
					<XCircle color="#cc3232" size={100} />
				) : (
					<CheckCircle color="#4db5db" size={100} />
				)}
			</div>
			{!isExpress && (
				<div
					className={styles.btn}
					onClick={() =>
						isExpress ? window.close() : history.push('/coupons')
					}
				>
					OK
				</div>
			)}
		</div>
	);
};
