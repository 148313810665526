import React, { FC, ReactElement } from 'react';
import styles from './Reward.module.css';
import { CouponReward as CouponRewardType } from 'types/coupon';
import classNames from 'classnames';
import { RootState } from 'types/store';
import find from 'lodash/find';
import { useSelector } from 'react-redux';
import { Currency } from 'types/currency';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from 'utils';

export enum RewardSize {
	SMALL = 'small',
	LARGE = 'large',
}

type CouponRewardProps = { reward: CouponRewardType; size: RewardSize };

export const CouponReward: FC<CouponRewardProps> = ({
	size,
	reward: { value, currency },
}: CouponRewardProps) => {
	const { t } = useTranslation();

	const currencyItem = useSelector(
		(store: RootState): Currency => {
			return find(store.currencies.currencies, { id: currency });
		}
	);

	const Value = ({ value }: { value: number }): ReactElement => {
		return <div className={styles.amount}>{formatCurrency(currencyItem, value)}</div>;
	};

	return (
		<div
			className={classNames(styles.reward, {
				[styles.small]: size === RewardSize.SMALL,
			})}
		>
			<div className={styles.value}>
				{currencyItem && (
					<img
						className={styles.currency}
						alt=""
						src={currencyItem.image.original.url}
					/>
				)}
				<Value value={value} />
			</div>
			<div className={styles.quantity}>{t('coupon.reward.perItem')}</div>
			{currencyItem && (
				<div className={styles.type}>
					{currencyItem?.name?.toUpperCase() || ''}
				</div>
			)}
		</div>
	);
};
