import React, { FC } from 'react';
import styles from './Count.module.css';
import { useTranslation } from 'react-i18next';

type CountProps = {
	count: number;
};

export const Count: FC<CountProps> = ({ count }: CountProps) => {
	const { t } = useTranslation();
	if (count >= 100) {
		return <React.Fragment></React.Fragment>;
	}

	let displayCount = 25;
	if (count > 75) {
		displayCount = 100;
	} else if (count > 50) {
		displayCount = 75;
	} else if (count > 25) {
		displayCount = 50;
	}

	return (
		<div className={styles.count}>
			{count < 1
				? t('coupon.countZero')
				: t('coupon.countLeft', { count: displayCount })}
		</div>
	);
};
