import { createSlice } from '@reduxjs/toolkit';
import config from 'config';
import { exchangeToken } from '../api/index';
import { fetchCurrencies } from './currencies';
import { EventsLogger } from 'views/common/EventsLogger';
import { resetReceipts } from './receipts';
import { resetCoupons } from './coupons';

const slice = createSlice({
	name: 'auth',
	initialState: {
		source: '',
		isGoJek: false,
		token: null,
		redirectTo: null,
		express: false,
		error: false,
		errorMessage: null,
		errorCode: '',
		data: {
			sub: '',
		},
	},
	reducers: {
		loginSuccess: (state, action) => {
			const {
				payload: { redirectUrl, express, partner, data },
			} = action;
			state.redirectTo = redirectUrl;
			state.express = express;
			state.source = partner;
			state.isGoJek = partner.toLowerCase() === 'gojek';
			state.token = data.token;
			state.data = data.data;
			state.errorCode = data.errorCode;
		},
		loginFailure: (state, action) => {
			return Object.assign(state, action.payload);
		},
		clearErrors: state => {
			state.errorCode = '';
			state.errorMessage = null;
			state.error = false;
		},
	},
});

export default slice.reducer;
const { loginSuccess, loginFailure, clearErrors } = slice.actions;

export const clearAutoBookError = () => async dispatch => {
	dispatch(clearErrors());
};

export const login = (partner, token, redirectUrl, express) => async dispatch => {
	try {
		const data = await exchangeToken(partner, token, express);
		if (data.errorCode === 'error.coupon.already_booked') {
			delete data.errorCode;
		}
		const isCouponError = data.errorCode?.length > 0;
		if (isCouponError) {
			EventsLogger.exception(`Coupon express booking failed`, {
				error: data.errorCode,
			});
			if (express) {
				redirectUrl = '/submitted';
			} else {
				redirectUrl = '/coupons';
			}
		}
		dispatch(resetReceipts());
		dispatch(resetCoupons());
		dispatch(
			loginSuccess({
				partner,
				data,
				redirectUrl: redirectUrl || config.redirectUrl,
				express,
			})
		);
		dispatch(fetchCurrencies());
	} catch (e) {
		EventsLogger.exception('Failed to login', { error: e.error?.response });
		dispatch(
			loginFailure({
				error: true,
				errorMessage: 'errors.SYSTEM',
				errorCode: e.error?.response?.status,
				data: e.data,
			})
		);
	}
};
