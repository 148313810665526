import React, { FC, useState, useEffect } from 'react';
import { Layout } from 'views/common/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { Coupon } from 'types/coupon';
import { useRouter } from 'hooks/router';
import isEmpty from 'lodash/isEmpty';
import styles from './Coupon.module.css';
import { CouponReward, RewardSize } from 'views/common/Reward';
import { bookCoupon, clearBookingError } from 'store/coupons';
import { PlusCircle, CheckCircle, Loader as MiniLoader } from 'react-feather';
import isNil from 'lodash/isNil';
import map from 'lodash/map';

import { useTranslation } from 'react-i18next';
import { CouponExpiry } from 'views/common/badges/CouponExpiry';
import { BookingCountdown } from 'views/common/badges/BookingCountdown';
import { Count } from 'views/common/badges/Count';
import ImageGallery from 'react-image-gallery';
import { getCoupon } from 'api';
import Loader from 'views/common/loader/Loader';
import { RootState } from 'types/store';
import { ErrorPage } from 'views/common/Error';
import { ErrorModal } from 'views/common/ErrorModal';
import { EventsLogger } from 'views/common/EventsLogger';
import { CouponDetails } from './CouponDetails';

export const CouponPage: FC = () => {
	const dispatch = useDispatch();
	const router = useRouter();
	const id = parseInt((router.query as { id: string }).id);
	const { t } = useTranslation();
	const [coupon, setCoupon] = useState<Coupon>({} as Coupon);
	const [hasError, setError] = useState<boolean>(false);
	const [cErrorCode, setErrorCode] = useState<string>('');
	const [couponLoading, setCouponLoading] = useState<boolean>(true);

	const {
		loading = false,
		error = false,
		errorCode = '',
	}: {
		loading: boolean;
		error: boolean;
		errorCode: string;
	} = useSelector((store: RootState) => Object.assign({}, store.coupons.bookings[id]));

	const bookingLoading = loading || couponLoading;

	useEffect(() => {
		if (loading) {
			setCouponLoading(false);
			return;
		}

		setCouponLoading(true);
		getCoupon(id).then(
			c => {
				setCouponLoading(false);
				setCoupon(c);
				EventsLogger.viewedCoupon(c);
			},
			e => {
				setCouponLoading(false);
				setErrorCode(e.response.status);
				console.error(e);
				setError(true);
			}
		);
	}, [id, loading]);

	const clearBookError = () => {
		dispatch(clearBookingError(id));
	};

	if (hasError) {
		return (
			<Layout title="coupon.couponDetails">
				<ErrorPage
					code={cErrorCode}
					title={'errors.coupon.title'}
					message={'errors.coupon.message'}
				></ErrorPage>
			</Layout>
		);
	}

	if (isEmpty(coupon)) {
		return <Loader></Loader>;
	}

	const images = coupon?.images?.map(i => {
		return { original: i.original.url };
	});

	return (
		<Layout title="coupon.couponDetails">
			<div id={styles.couponPage}>
				<ErrorModal
					title="errors.book.title"
					message="errors.book.message"
					visible={error}
					errorCode={errorCode}
					onClose={clearBookError}
				></ErrorModal>
				<div className={styles.image}>
					<Count count={coupon.remainingQuantity} />
					<ImageGallery
						showPlayButton={false}
						useBrowserFullscreen={false}
						showThumbnails={false}
						items={images}
						showFullscreenButton={true}
						showBullets={true}
					/>
					<CouponExpiry coupon={coupon} />
				</div>
				<div className={styles.header}>
					<div className={styles.priceHeader}>
						<CouponReward reward={coupon.reward} size={RewardSize.LARGE} />
						<div className={styles.btn}>
							{bookingLoading && (
								<MiniLoader
									size={30}
									color="#4db4dc"
									className={styles.loader}
								/>
							)}
							{!bookingLoading && !isNil(coupon.booking) && (
								<CheckCircle size={30} color="#4db4dc" />
							)}
						</div>
					</div>
					<div className={styles.name}>{coupon.name}</div>
					{coupon.booking && (
						<BookingCountdown expiry={coupon.booking.expiresAt} />
					)}
					<div className={styles.conditions}>
						{coupon.maxCouponsPerUser && (
							<div>
								{t('coupon.limit')}{' '}
								{coupon.booking?.remainingQuantity ||
									coupon.maxCouponsPerUser}
							</div>
						)}
						{coupon.minimumItems > 1 && (
							<div>
								{t('coupon.mustBuy')} {coupon.minimumItems}
							</div>
						)}
					</div>
				</div>
				<div className={styles.details}>
					<h3>{t('coupon.details.details')}</h3>
					<p>
						{coupon.minimumItems > 0
							? t('coupon.details.noMinPurchase', {
									count: coupon.minimumItems,
							  })
							: t('coupon.details.noMinPurchase')}
					</p>
					<p>
						{coupon.maxCouponsPerUser
							? t('coupon.details.maxPurchase', {
									count: coupon.maxCouponsPerUser,
							  })
							: t('coupon.details.noMaxPurchase')}
					</p>
					<p>{coupon.chains.length < 1 && t('coupon.details.allChains')}</p>
					<p>
						{coupon.excludeChains
							? t('coupon.details.excludeChains', {
									chains: map(coupon.chains, 'name').join(', '),
							  })
							: t('coupon.details.includeChains', {
									chains: map(coupon.chains, 'name').join(', '),
							  })}
					</p>
					<p>{t('coupon.details.bookingDetail')}</p>
				</div>

				<CouponDetails coupon={coupon}></CouponDetails>
			</div>
		</Layout>
	);
};
