import React, { FC } from 'react';
import { Tip } from './Tip';
import styles from './LightingTip.module.css';
import receipt from '../../assets/img/short_receipt.png';
import mobile from '../../assets/img/mobile_snap.png';
import { Check, X } from 'react-feather';

export const LightingTip: FC<{ isActive: boolean }> = ({
	isActive,
}: {
	isActive: boolean;
}) => {
	return (
		<Tip
			primaryTip="tips.light.primaryTip"
			secondaryTip="tips.light.secondTip"
			thirdTip="tips.light.thirdTip"
		>
			<div className={styles.images}>
				{isActive && <div className={styles.overlay}></div>}
				{isActive && (
					<img className={styles.receipt} src={receipt} alt="receipt" />
				)}
				{isActive && <img className={styles.mobile} src={mobile} alt="mobile" />}
				{isActive && <X color="#ffffff" className={styles.wrong} />}
				{isActive && <Check color="#ffffff" className={styles.right} />}
			</div>
		</Tip>
	);
};
