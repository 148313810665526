import React, { FC, useState } from 'react';
import styles from './Tips.module.css';
import { ReceiptTip } from './ReceiptTip';
import { LightingTip } from './LightingTip';
import SwipeableViews from 'react-swipeable-views';
import { MultiReceiptTip } from './MultiReceiptTip';
import classNames from 'classnames';
import { ChevronRight } from 'react-feather';
import { Camera } from './Camera';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'hooks/router';
import { createReceiptAction } from 'store/receipts';
import Loader from 'views/common/loader/Loader';
import { AcceptTip } from './AcceptTip';

export const Tips: FC = () => {
	const router = useRouter();
	const dispatch = useDispatch();
	const [activeTab, setActiveTab] = useState<number>(0);
	const [cameraLoading, setCameraLoading] = useState<boolean>(false);

	const { t } = useTranslation();

	const handleNext = () => {
		if (activeTab < 4) {
			setActiveTab(activeTab + 1);
		}
	};

	const next = (image: string) => {
		dispatch(createReceiptAction(image));
		setCameraLoading(false);
		router.history.replace('/upload');
	};

	if (cameraLoading) {
		return <Loader></Loader>;
	}

	return (
		<div className={styles.tips}>
			<SwipeableViews
				animateTransitions={false}
				className={styles.tips}
				index={activeTab}
				onChangeIndex={setActiveTab}
				style={{ flex: '1' }}
				containerStyle={{ height: '100%' }}
			>
				<AcceptTip index={activeTab + 1} isActive={activeTab === 0} />
				<AcceptTip index={activeTab + 1} isActive={activeTab === 1} />
				<ReceiptTip isActive={activeTab === 2} />
				<LightingTip isActive={activeTab === 3} />
				<MultiReceiptTip isActive={activeTab === 4} />
			</SwipeableViews>
			<div className={styles.steps}>
				<Camera
					isLoading={() => setCameraLoading(true)}
					isActive={true}
					onChange={next}
				>
					<div>{t('SKIP')}</div>
				</Camera>
				<div className={styles.dots}>
					<div
						onClick={() => setActiveTab(0)}
						className={classNames(styles.dot, {
							[styles.active]: activeTab === 0,
						})}
					></div>
					<div
						onClick={() => setActiveTab(1)}
						className={classNames(styles.dot, {
							[styles.active]: activeTab === 1,
						})}
					></div>
					<div
						onClick={() => setActiveTab(2)}
						className={classNames(styles.dot, {
							[styles.active]: activeTab === 2,
						})}
					></div>
					<div
						onClick={() => setActiveTab(3)}
						className={classNames(styles.dot, {
							[styles.active]: activeTab === 3,
						})}
					></div>
					<div
						onClick={() => setActiveTab(4)}
						className={classNames(styles.dot, {
							[styles.active]: activeTab === 4,
						})}
					></div>
				</div>
				<Camera
					isLoading={() => setCameraLoading(true)}
					isActive={activeTab === 4}
					onChange={next}
				>
					<ChevronRight onClick={handleNext} />
				</Camera>
			</div>
		</div>
	);
};
