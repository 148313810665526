const enTranslations = {
	Latest: 'Latest',
	Completed: 'Completed',
	Rejected: 'Rejected',
	Sent: 'Sent',
	Completed_no_coupons: 'Completed but no success redemption',
	completed_no_coupons: 'Completed but no success redemption',
	completed: 'Completed',
	rejected: 'Rejected',
	sent: 'Sent',
	pending: 'Pending',
	processing: 'Processing',
	History: 'History',
	'I need help with this receipt': 'I NEED HELP WITH THIS RECEIPT',
	Status: 'Status',
	Tag: 'Tag',
	Type: 'Type',
	Reason: 'Reason',

	SKIP: 'SKIP',

	supermarket_minimarket_pharmacies: 'Supermarket, Minimarket, Pharmacies',
	restaurants: 'Restaurants',
	apparel_clothing: 'Apparel/Clothing',
	petrol_transportation: 'Petrol / Transportation',
	electronics: 'Electronics',
	ecommerce: 'Online transaction',
	others: 'Others',
	unknown: 'Unknown',

	not_in_household: 'Not in Household',
	employer: 'Employer',
	uncle_aunt: 'Uncle/Aunt',
	grandparents: 'Grandparents',
	children: 'Children',
	sibling: 'Sibling',
	parents: 'Parents',
	spouse: 'Spouse',
	myself: 'Myself',

	'Your receipt is being submitted': 'Your receipt is being submitted',
	'Thank you, your receipt is submitted': 'Thank you, your receipt is submitted',
	'Your receipt is being processed': 'Your receipt is being processed',

	'Store Name': 'Store Name',
	'Total value': 'Total value',
	Date: 'Date',
	Rewards: 'Rewards',
	'Coupon(s) Redeemed': 'Coupon(s) Redeemed',
	'Coupon Redeemed Value': 'Coupon Redeemed Value',
	'Purchase time': 'Purchase time',

	'Who decides this purchase?': 'Who decides this purchase?',
	'What is the type of this receipt?': 'What is the type of this receipt?',
	'Please pick one': 'Please pick one',
	SEND: 'SEND',
	RETAKE: 'RETAKE',
	FINISH: 'FINISH',

	tips: {
		multi: {
			primaryTip: 'One Receipt, One Snap',
			secondTip: "Got multiple receipts? That's great!",
			thirdTip: 'Just submit one at a time.',
		},
		light: {
			primaryTip: 'Make Sure The Receipt Is Legible',
			secondTip: 'Snap in a well-lit environment.',
			thirdTip: "The receipt also shouldn't be crumpled nor torn.",
		},
		receipt: {
			primaryTip: 'Snap the Whole Receipt in Portrait Position',
			secondTip:
				'Make sure to snap in portrait position & the whole receipt fits in the camera frame. Use (+) button for long receipts.',
		},
		accept: {
			1: {
				primaryTip: 'What type of receipts is accepted?',
				secondTip:
					'Make sure to read that first so your receipts won’t get rejected.',
				1: 'Receipts accepted can be from anywhere as long as it includes store name and address, product name(s), product quantity, product price(s), and total price.',
				2: 'Maximum transaction date on the receipt is 3 days ago.',
				3: 'Items purchased are for personal use only, or from the same household. Not for resell.',
				4: 'Real receipts. Receipts are not modified nor duplicated.',
			},
			2: {
				primaryTip: 'What type of receipts is accepted?',
				secondTip:
					'Make sure to read that first so your receipts won’t get rejected.',
				1: 'Only printed receipts, not handwritten.',
				2: 'Snap your receipt from your own country',
				3: 'Do not split a single purchase to make multiple receipt.',
				4: 'Receipts must be of normal size of printed store receipts (not A4, not invoices)',
			},
		},
	},
	snap: {
		submit: {
			success: {
				title: 'Thank you for submitting your receipt',
				message:
					'If your receipt contains the valid items, you will receive your cashback soon. Keep snapping & keep earning',
				gojek: {
					title: 'Thank you for submitting your receipt',
					message:
						'If your receipt contains the valid items, you will receive your cashback within the next 7 days. Keep snapping & keep earning',
				},
			},
		},
	},
	coupon: {
		coupon: 'Coupon',
		couponDetails: 'Coupon Details',
		couponTraction: 'Book Coupon to receive instant reward\nwhen you Snap!',
		limit: 'LIMIT',
		mustBuy: 'MUST BUY',
		expiryDays: 'Expires in {{days}} days',
		expiryHours: 'Expires in {{hours}} hours',
		countLeft: '< {{count}} Left',
		countZero: 'No coupons left',
		available: {
			available: 'AVAILABLE',
			emptyTitle:
				'Oh no! We are sorry, but all the available Coupons are unavailable!',
			emptyMessage: 'Well, you know what we mean, right? Please check again soon!',
		},
		expiring: {
			expiring: 'EXPIRING',
			emptyTitle: 'Well, no Coupons are going to expire soon.',
			emptyMessage: 'But they’re not gonna last forever, so start booking!',
		},
		booked: {
			booked: 'BOOKED',
			emptyTitle:
				'Hey! You could be getting Cashbacks for shopping, but you’re not!',
			emptyMessage:
				'Grab your Coupons now to receive Cashbacks for shopping. See what Coupons we have for you!',
		},
		reward: {
			perItem: 'Per-item',
		},
		receipt: {
			Type: 'Type',
			Status: 'Status',
			Tag: 'Tag',
		},
		details: {
			details: 'Details',
			bookingExpiresIn: 'Booking expires in',
			noMinPurchase: '- There is no minimum purchase require',
			minPurchase:
				'- A minimum purchase of {{count}} item(s) is required per receipt',
			noMaxPurchase: '- There is no maximum limit',
			maxPurchase: '- Only valid for a maximum of {{count}} item(s) per user',
			allChains: '- This promo is valid for all stores',
			includeChains: '- This promo is valid for purchases in {{chains}}',
			excludeChains: '- This promo is not valid for purchases in {{chains}}',
			bookingDetail:
				'- Your booked Coupon(s) will only last for 24 hours in your ‘Booked‘ tab. That will apply even if the promotion is expiring in less than 24 hours. You can book the Coupon(s) again in the ‘Available‘ / ‘Expiring‘ Coupons tab.',
			howToEarn: 'How to earn',
			howToEarn1: '1. Buy product according to the Coupon’s terms and condition',
			howToEarn2:
				'2. Take photo and upload your receipt by pressing snap button',
			howToEarn3:
				'3. Ensure that your receipt is clear and all parts of the receipts should be included in the image',
			howToEarn4: '4. Rewards will be sent to your account',
			productDetails: 'Product Details',
		},
		sorting: {
			sorting: 'Sort coupons by',
			brandAsc: 'Brand A-Z',
			brandDesc: 'Brand Z-A',
			dateAsc: 'Oldest-Newest',
			dateDesc: 'Newest-Oldest',
			expiryAsc: 'Soonest-Latest',
			expiryDesc: 'Latest-Soonest',
			brandAscTxt: 'Brand Name A-Z',
			brandDescTxt: 'Brand Name Z-A',
			startAscTxt: 'Start Date from Oldest to Newest',
			startDescTxt: 'Start Date from Newest to Oldest',
			bookAscTxt: 'Book Date from Oldest to Newest',
			bookDescTxt: 'Book Date from Newest to Oldest',
			expiryAscTxt: 'Expiry Date from Soonest to Latest',
			expiryDescTxt: 'Expiry Date from Latest to Soonest',
		},
	},
	express: {
		error: {
			coupon: {
				expired: 'The Coupon promotion has ended.',
				quantity_exceeded: 'You have reached maximum limit of this Coupon.',
				not_found: 'The Coupon is no longer available.',
				unknown: 'Sorry for the inconvenience. Please try again later.',
				internal: 'Sorry for the inconvenience. Please try again later.',
				null: 'Sorry for the inconvenience. Please try again later.',
			},
		},
	},
	sorry: 'Sorry',
	error: {
		coupon: {
			expired:
				"The Coupon promotion has ended. Let's see what other Coupons we have for you!",
			quantity_exceeded:
				"You have reached maximum limit of this Coupon. Let's see what other Coupons we have for you!",
			not_found:
				"The Coupon is no longer available. Let's see what other Coupons we have for you!",
			unknown: 'Sorry for the inconvenience. Please try again later.',
			internal: 'Sorry for the inconvenience. Please try again later.',
			null: 'Sorry for the inconvenience. Please try again later.',
		},
	},
	errors: {
		SYSTEM: 'The system is unavailable at the moment',
		PROMO_END: 'The Coupon promotion has ended',
		COUPON_UNAVAILABLE: 'The Coupon is no longer available',
		COUPON_LIMIT: 'You have reached maximum limit of this Coupon',
		COUPONS_ERROR: 'Unable to load Coupons.',
		coupons: {
			title: 'Unable to load Coupons',
			message: 'Sorry for the inconvenience. Please try again later.',
		},
		coupon: {
			title: 'Unable to load Coupon',
			message: 'Sorry for the inconvenience. Please try again later.',
		},
		book: {
			title: 'Unable to book Coupon',
			message: 'Sorry for the inconvenience. Please try again later.',
		},
		receipt: {
			title: 'Unable to load receipt detail',
			message: 'Sorry for the inconvenience. Please try again later.',
		},
		receipts: {
			title: 'Unable to load receipts',
			message: 'Sorry for the inconvenience. Please try again later.',
			create: {
				title: 'Unable to create receipt',
				message: 'Sorry for the inconvenience. Please try again later.',
			},
			send: {
				title: 'Unable to send receipt',
				message: 'Sorry for the inconvenience. Please try again later.',
			},
			no_coupons_available: {
				title: 'Unable to create receipt',
				message: 'No coupon available at the moment.',
			},
		},
		generic: {
			title: 'The system is unavailable at the moment',
			message: 'Sorry for the inconvenience. Please try again later.',
		},
		auth: {
			forbidden: {
				message:
					'Sorry, your account has no permission to open Coupon. For further details, you can contact customer service ',
				action: 'here.',
			},
		},
	},
};

export default enTranslations;
