import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import styles from './Modal.module.css';

type ModalProps = {
	title: string;
	message: string;
	errorCode: string;
	visibile: boolean;
	onClose: any;
};

export const CustomModal: FC<ModalProps> = ({
	title,
	message,
	visibile,
	errorCode,
	onClose,
}: ModalProps) => {
	const { t } = useTranslation();

	return (
		<Modal
			isOpen={visibile}
			onRequestClose={onClose}
			className={styles.modal}
			overlayClassName={styles.overlay}
		>
			<div className={styles.title}>{t(title)}</div>
			<div className={styles.message}>{t(message)}</div>
			<div className={styles.message}>Error: ({errorCode || 'FE'})</div>
			<button className={styles.btn} onClick={onClose}>
				OK
			</button>
		</Modal>
	);
};
