import React, { FC } from 'react';
import styles from './BookingCountdown.module.css';
import 'moment-duration-format';
import Countdown, { zeroPad } from 'react-countdown';
import { useTranslation } from 'react-i18next';

type BookingCountdownProps = {
    expiry: Date;
}

export const BookingCountdown: FC<BookingCountdownProps> = ({ expiry }: BookingCountdownProps) => {
    const { t } = useTranslation();

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            return <React.Fragment></React.Fragment>;
        } else {
            return <div className={styles.expiry}>
                {t('coupon.details.bookingExpiresIn')}
                {' '}
                {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
            </div>
        }
    };

    return (
        <Countdown
            date={expiry}
            renderer={renderer}
        />
    );
} 
