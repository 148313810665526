import React, { FC } from 'react';
import styles from './CouponExpiry.module.css';
import { Coupon } from 'types/coupon';
import config from '../../../config';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Clock } from 'react-feather';

type CouponExpiryProps = {
    coupon: Coupon;
}

export const CouponExpiry: FC<CouponExpiryProps> = ({ coupon }: CouponExpiryProps) => {
    const { t } = useTranslation();

    if (coupon.booking) {
        return <React.Fragment></React.Fragment>
    }

    const currentTime = moment();
    const expiryTime = moment(coupon.endDate);

    const diff = moment.duration(expiryTime.diff(currentTime));
    const days = diff.asDays();
    const hours = diff.asHours();
    const daysToShow = config.expiryDays;

    // expired or not expiring yet
    if (days < 0 || days > daysToShow) {
        return <React.Fragment></React.Fragment>;
    }

    // expiring in range of 1 day - [daysToShow] days
    if (days > 1 && days < daysToShow) {
        return <div className={styles.expiry}>
            <Clock size={12} />
            {t('coupon.expiryDays', { days: days.toFixed(0) })}
        </div>
    }

    return <div className={styles.expiry}>
        <Clock size={12} />
        {t('coupon.expiryHours', { hours: hours.toFixed(0) })}</div>
} 