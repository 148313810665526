import React, { FC } from 'react';
import styles from './Coupon.module.css';
import ReactPlaceholder from 'react-placeholder';
import 'react-placeholder/lib/reactPlaceholder.css';

export const CouponPlaceholder: FC = () => {
	return (
		<div className={styles.coupon}>
			<div className={styles.imgContainer}>
				<ReactPlaceholder
					type="rect"
					ready={false}
					color="#E0E0E0"
					style={{ width: '100%', height: 100, margin: 0 }}
				>
					<span></span>
				</ReactPlaceholder>
			</div>
			<div className={styles.mainInfo}>
				<div className={styles.price}>
					<ReactPlaceholder
						type="rect"
						ready={false}
						color="#E0E0E0"
						style={{ width: 75, height: 18, margin: 0 }}
					>
						<span></span>
					</ReactPlaceholder>
				</div>
				<div className={styles.btn}>
					<ReactPlaceholder
						type="round"
						ready={false}
						color="#E0E0E0"
						style={{ width: 30, height: 30 }}
					>
						<span></span>
					</ReactPlaceholder>
				</div>
			</div>
			<div className={styles.priceDetails}>
				<ReactPlaceholder
					type="rect"
					ready={false}
					color="#E0E0E0"
					style={{ width: 20, height: 10, margin: '0 5px' }}
				>
					<span></span>
				</ReactPlaceholder>
				<ReactPlaceholder
					type="rect"
					ready={false}
					color="#E0E0E0"
					style={{ width: 40, height: 12, margin: '2px 5px' }}
				>
					<span></span>
				</ReactPlaceholder>
			</div>
			<div className={styles.name}>
				<ReactPlaceholder
					type="rect"
					ready={false}
					color="#E0E0E0"
					style={{ width: 100, height: 12, margin: '2px 5px' }}
				>
					<span></span>
				</ReactPlaceholder>
			</div>
			<div className={styles.conditions}>
				<ReactPlaceholder
					type="rect"
					ready={false}
					color="#E0E0E0"
					style={{ width: 40, height: 10, margin: 2 }}
				>
					<span></span>
				</ReactPlaceholder>
				<ReactPlaceholder
					type="rect"
					ready={false}
					color="#E0E0E0"
					style={{ width: 40, height: 10, margin: 2 }}
				>
					<span></span>
				</ReactPlaceholder>
			</div>
		</div>
	);
};
