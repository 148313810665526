import { createSlice } from '@reduxjs/toolkit';
import {
	createReceipt,
	editReceipt,
	commitReceipt,
	fetchReceipts,
	uploadReceiptImage,
} from '../api/index';
import {
	PurchaseTag,
	PurchaseDecision,
	ReceiptStatus,
	ReceiptPageType,
	ReceiptStatistics,
} from 'types/receipt';
import { ReceiptTypeState, ReceiptsState } from 'types/store';
import { ReceiptsQuery } from 'types/queries';
import { EventsLogger, CustomEvent } from 'views/common/EventsLogger';

const receiptsInitialState: ReceiptTypeState = {
	receipts: [],
	isLoading: true,
	pagination: { current: 1, total: 1 },
	query: { page: 1 },
};

const pagesInitialState: ReceiptsState = {
	statistics: { completed: 0, rejected: 0, sent: 0 },
	[ReceiptPageType.LATEST]: Object.assign({}, receiptsInitialState, {
		query: { page: 1 },
	}),
	[ReceiptPageType.REJECTED]: Object.assign({}, receiptsInitialState, {
		query: { page: 1, status: ReceiptStatus.REJECTED },
	}),
	[ReceiptPageType.SENT]: Object.assign({}, receiptsInitialState, {
		query: { page: 1, status: ReceiptStatus.SENT },
	}),
	[ReceiptPageType.COMPLETED]: Object.assign({}, receiptsInitialState, {
		query: { page: 1, status: ReceiptStatus.COMPLETED },
	}),
};

const slice = createSlice({
	name: 'receipts',
	initialState: {
		receiptInProgress: null,
		images: [] as string[],
		sent: false,
		loading: false,
		error: false,
		submitError: false,
		errorTitle: (null as unknown) as string,
		errorCode: '',
		errorMessage: (null as unknown) as string,
		pages: Object.assign({}, pagesInitialState),
	},
	reducers: {
		reset: state => {
			state.receiptInProgress = null;
			state.images = [] as string[];
			state.loading = false;
			state.sent = false;
			state.error = false;
			state.submitError = false;
			state.errorTitle = (null as unknown) as string;
			state.errorCode = '';
			state.errorMessage = (null as unknown) as string;
			state.pages = Object.assign({}, pagesInitialState);
		},
		fetchingReceipts: (state, { payload }) => {
			const { type }: { type: ReceiptPageType } = payload;
			state.pages[type].isLoading = true;
		},
		fetchReceiptsFailed: (state, { payload }) => {
			const {
				type,
				ignoreError,
				errorCode,
			}: {
				type: ReceiptPageType;
				ignoreError: boolean;
				errorCode: string;
			} = payload;
			state.pages[type].isLoading = false;
			if (ignoreError) {
				return;
			}
			state.error = true;
			state.errorMessage = 'errors.receipts.message';
			state.errorTitle = 'errors.receipts.title';
			state.errorCode = errorCode;
		},
		fetchReceiptsSuccess: (state, { payload }) => {
			const {
				receipts,
				statistics,
				type,
			}: {
				receipts: ReceiptTypeState;
				statistics: ReceiptStatistics;
				type: ReceiptPageType;
			} = payload;

			if (receipts.pagination.current > 1) {
				state.pages[type].receipts = state.pages[type].receipts.concat(
					receipts.receipts
				);
				state.pages[type].pagination = receipts.pagination;
				state.pages[type].query = receipts.query;
				state.pages[type].isLoading = false;
			} else {
				state.pages[type] = receipts;
			}

			state.pages.statistics = statistics;
		},
		createReceiptSuccess: (state, { payload: { receiptInProgress, image } }) => {
			state.receiptInProgress = receiptInProgress;
			state.images = [image];
			state.error = false;
			state.loading = false;
			state.sent = false;
			state.submitError = false;
		},
		createReceiptFailed: (state, { payload: { errorCode } }) => {
			state.receiptInProgress = null;
			state.loading = false;
			state.error = true;
			if (errorCode == 406) {
				state.errorMessage = 'errors.receipts.no_coupons_available.message';
				state.errorTitle = 'errors.receipts.no_coupons_available.title';
			} else {
				state.errorMessage = 'errors.receipts.create.message';
				state.errorTitle = 'errors.receipts.create.title';
			}
			state.errorCode = errorCode;
		},
		chooseImageReceiptSuccess: (state, { payload: { image } }) => {
			state.images.push(image);
		},
		replaceImageReceipt: (state, { payload: { image } }) => {
			state.images[state.images.length - 1] = image;
		},
		sendReceiptLoading: state => {
			state.loading = true;
			state.error = false;
			state.sent = false;
		},
		sendReceiptSuccess: state => {
			state.images.length = 0;
			state.receiptInProgress = null;
			state.loading = false;
			state.sent = true;
		},
		sendReceiptFailure: (state, { payload: { errorCode } }) => {
			state.images.length = 0;
			state.receiptInProgress = null;
			state.sent = true;
			state.loading = false;
			state.submitError = true;
			state.errorMessage = 'errors.receipts.send.message';
			state.errorTitle = 'errors.receipts.send.title';
			state.errorCode = errorCode;
		},
		clearError: state => {
			state.error = false;
			state.errorMessage = (null as unknown) as string;
			state.errorTitle = (null as unknown) as string;
		},
	},
});

export default slice.reducer;

// Actions
const {
	fetchingReceipts,
	fetchReceiptsSuccess,
	fetchReceiptsFailed,
	createReceiptSuccess,
	createReceiptFailed,
	chooseImageReceiptSuccess,
	replaceImageReceipt,
	sendReceiptSuccess,
	sendReceiptFailure,
	sendReceiptLoading,
	clearError,
	reset,
} = slice.actions;

const receiptsSuccess = (dispatch: any, payload: any, type: ReceiptPageType) => {
	const { receipts, statistics } = payload;
	dispatch(fetchReceiptsSuccess({ receipts, statistics, type }));
};

export const clearReceiptError = clearError;
export const resetReceipts = reset;
export const fetchLatest = (query: ReceiptsQuery) => async dispatch => {
	try {
		dispatch(fetchingReceipts({ type: ReceiptPageType.LATEST }));
		const payload = await fetchReceipts(query);
		receiptsSuccess(dispatch, payload, ReceiptPageType.LATEST);
	} catch (error) {
		EventsLogger.exception('Failed to fetch receipts', { error: error.reponse });
		dispatch(fetchReceiptsFailed({ type: ReceiptPageType.LATEST }));
	}
};

export const fetchRejected = (
	query: ReceiptsQuery,
	ignoreError?: boolean
) => async dispatch => {
	try {
		dispatch(fetchingReceipts({ type: ReceiptPageType.REJECTED }));
		const payload = await fetchReceipts(query);
		receiptsSuccess(dispatch, payload, ReceiptPageType.REJECTED);
	} catch (error) {
		EventsLogger.exception('Failed to fetch receipts', { error: error.reponse });
		dispatch(fetchReceiptsFailed({ type: ReceiptPageType.REJECTED, ignoreError }));
	}
};

export const fetchSent = (
	query: ReceiptsQuery,
	ignoreError?: boolean
) => async dispatch => {
	try {
		dispatch(fetchingReceipts({ type: ReceiptPageType.SENT }));
		const payload = await fetchReceipts(query);
		receiptsSuccess(dispatch, payload, ReceiptPageType.SENT);
	} catch (error) {
		EventsLogger.exception('Failed to fetch receipts', { error: error.reponse });
		dispatch(
			fetchReceiptsFailed({
				type: ReceiptPageType.SENT,
				ignoreError,
				errorCode: error.response?.status,
			})
		);
	}
};

export const fetchCompleted = (
	query: ReceiptsQuery,
	ignoreError?: boolean
) => async dispatch => {
	try {
		dispatch(fetchingReceipts({ type: ReceiptPageType.COMPLETED }));
		const payload = await fetchReceipts(query);
		receiptsSuccess(dispatch, payload, ReceiptPageType.COMPLETED);
	} catch (error) {
		EventsLogger.exception('Failed to fetch receipts', { error: error.reponse });
		dispatch(
			fetchReceiptsFailed({
				type: ReceiptPageType.COMPLETED,
				ignoreError,
				errorCode: error.response?.status,
			})
		);
	}
};

export const initializeReceipts = () => async dispatch => {
	try {
		EventsLogger.logCustomEvent(CustomEvent.VIEWED_RECEIPTS);
		dispatch(fetchLatest(pagesInitialState.latest.query));
		dispatch(fetchRejected(pagesInitialState.rejected.query, true));
		dispatch(fetchSent(pagesInitialState.sent.query, true));
		dispatch(fetchCompleted(pagesInitialState.completed.query, true));
	} catch (e) {
		EventsLogger.exception('Failed to fetch receipts', { error: e.reponse });
		console.error('Initializing receipts', e);
		dispatch(
			fetchReceiptsFailed({
				type: ReceiptPageType.LATEST,
				errorCode: e.response?.status,
			})
		);
	}
};
export const createReceiptAction = (image: string) => async dispatch => {
	try {
		dispatch(sendReceiptLoading());
		let data = await createReceipt();
		EventsLogger.logCustomEvent(CustomEvent.START_SNAP);
		EventsLogger.logCustomEvent(CustomEvent.UPLOADED_IMAGE);
		dispatch(createReceiptSuccess({ receiptInProgress: data, image: image }));
	} catch (e) {
		EventsLogger.exception('Failed to create receipt', { error: e.response });
		dispatch(createReceiptFailed({ errorCode: e.response?.status }));
		return console.error(e.message);
	}
};

export const uploadReceiptImageAction = (image: string) => async dispatch => {
	EventsLogger.logCustomEvent(CustomEvent.UPLOADED_IMAGE);
	dispatch(chooseImageReceiptSuccess({ image }));
};

export const replaceReceiptImageAction = (image: string) => async dispatch => {
	EventsLogger.logCustomEvent(CustomEvent.REPLACE_IMAGE);
	dispatch(replaceImageReceipt({ image }));
};

export const sendReceipt = (
	id: number,
	images: string[],
	decision: PurchaseDecision,
	purchase: PurchaseTag
) => async dispatch => {
	try {
		dispatch(sendReceiptLoading());
		const imagesPromises = images.map(image => {
			return uploadReceiptImage(id, image);
		});
		await Promise.all(imagesPromises);
		await editReceipt(id, purchase, decision);
		await commitReceipt(id);
		EventsLogger.logCustomEvent(CustomEvent.FINALIZED_SNAP);
		dispatch(sendReceiptSuccess());
	} catch (e) {
		EventsLogger.exception('Failed to finalize snap', { error: e.response });
		dispatch(sendReceiptFailure({ errorCode: e.response?.status }));
	}
};
