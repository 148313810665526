import React from 'react';
import Loader from './Loader';

interface Props {
	isLoading: boolean;
	children: React.ReactElement;
}

/**
 * A component used to display a loading while some processes are loading.
 */
const AppLoader: React.FC<Props> = ({ isLoading, children }: Props) => {
	if (isLoading) {
		return <Loader></Loader>;
	}
	return children;
};

export default AppLoader;
