import React from 'react';
import styles from './Toast.module.css';

export const Toast = ({ children }: { children: any }) => {
	return (
		<div className={styles.toast}>
			<div>{children}</div>
		</div>
	);
};
