import React, { FC } from 'react';
import styles from './ReceiptLoader.module.css';
import { Receipt as ReceiptType } from 'types/receipt';
import { ChevronRight } from 'react-feather';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useRouter } from 'hooks/router';
import { useTranslation } from 'react-i18next';
import {normalizeReceiptStatus} from "../../utils";

type ReceiptLoaderProps = {
	title: string;
	receipts: ReceiptType[];
	loadMore: any;
};

type ReceiptProps = {
	receipt: ReceiptType;
};

const Receipt: FC<ReceiptProps> = ({ receipt }: ReceiptProps) => {
	const { history } = useRouter();
	const { t } = useTranslation();

	return (
		<div
			className={styles.receipt}
			onClick={() => {
				history.push(`/history/${receipt.id}`);
			}}
		>
			<div className={styles.data}>
				<p>ID: {receipt.id}</p>
				<p>{t(normalizeReceiptStatus(receipt))}</p>
			</div>
			<ChevronRight />
		</div>
	);
};

export const ReceiptLoader: FC<ReceiptLoaderProps> = ({
	receipts,
	title,
	loadMore,
}: ReceiptLoaderProps) => {
	const { t } = useTranslation();

	return (
		<div className={styles.container}>
			<div className={styles.title}>{t(title)}</div>
			<div className={styles.receipts}>
				<InfiniteScroll
					dataLength={receipts.length}
					next={loadMore}
					hasMore={true}
					loader={<span></span>}
					scrollableTarget="layout-body"
				>
					{receipts.map(r => {
						return <Receipt key={r.id} receipt={r} />;
					})}
				</InfiniteScroll>
			</div>
		</div>
	);
};
