import { bugsnag } from 'bugsnag';
import { amplitudeClient as amplitude } from 'amplitude';
//import firebase from 'firebase';
import { Coupon } from 'types/coupon';
import config from 'config';

//const EventName = firebase.analytics.EventName;
//type Item = firebase.analytics.Item;

// const LISTS = {
// 	COUPONS: 'Coupons',
// 	RECEIPTS: 'Receipts',
// };

export enum CustomEvent {
	LOGIN = 'login',
	TAB_CLICK = 'tab_click',
	VIEW_COUPON_LIST = 'view_coupon_list',
	VIEW_COUPON = 'view_coupon',
	BOOK_COUPON = 'booked_coupon',
	BOOK_COUPON_EXPRESS = 'booked_coupon_express',
	START_SNAP = 'started_snap',
	UPLOADED_IMAGE = 'uploaded_image',
	REPLACE_IMAGE = 'replaced_image',
	FINALIZED_SNAP = 'finalized_snap',
	VIEWED_RECEIPT = 'viewed_receipt',
	VIEWED_RECEIPTS = 'viewed_receipts',
	CUSTOMER_SUPPORT = 'customer_support',
}

const getData = (data) => {
	return { ...data, version: config.version };
}

export class EventsLogger {
	static viewedCoupon = (coupon: Coupon) => {
		amplitude.logEvent(CustomEvent.VIEW_COUPON, getData({
			coupon,
		}));
	};

	static tabClicked = (tabName: string) => {
		EventsLogger.logCustomEvent(CustomEvent.TAB_CLICK, getData({ name: tabName }));
	};

	static viewedCouponInList = (coupon: Coupon, listName: string) => {
		amplitude.logEvent(CustomEvent.VIEW_COUPON_LIST, getData({
			coupon,
			listName
		}));
	};

	static bookCoupon = (coupon: Coupon) => {
		amplitude.logEvent(CustomEvent.BOOK_COUPON, getData({ coupon }));

		const revenew = new amplitude.Revenue()
			.setProductId(coupon?.id?.toString())
			.setPrice(coupon?.reward?.value)
			.setEventProperties({ currency: coupon?.reward?.currency });

		amplitude.logRevenueV2(revenew);
	};

	static bookCouponExpress = (couponId: number) => {
		amplitude.logEvent(CustomEvent.BOOK_COUPON_EXPRESS, getData({ id: couponId }));

		const revenew = new amplitude.Revenue()
			.setProductId(couponId.toString());

		amplitude.logRevenueV2(revenew);
	};

	static logCustomEvent = (event: CustomEvent, data?: any) => {
		amplitude.logEvent(event, getData(data));
	};

	static setUser = (source: string, express: boolean, email: string) => {
		amplitude.setUserId(email);
		amplitude.setUserProperties({ email, source, express: !!express });
		bugsnag.setUser(email, email, email);
	};

	static login = (method: string, express: boolean, email: string) => {
		EventsLogger.logCustomEvent(CustomEvent.LOGIN, getData({ email, source: method, method }));
		const identity = new amplitude.Identify()
			.add('version', config.version)
			.add('email', email)
			.add('source', method)
			.add('express', (express as unknown) as string);
		amplitude.identify(identity);
	};

	static exception = (message: string, data?: { error: any }) => {
		bugsnag.notify(new Error(message as string), event => {
			event.addMetadata('data', data ?? {});
		});

		amplitude.logEvent('exception', getData({
			description: message,
			fatal: false,
			...data,
		}));
	};
}
