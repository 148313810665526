import React, { FC } from 'react';
import { CustomModal } from 'views/common/modal/Modal';

type ErrorModalProps = {
	message?: string;
	title?: string;
	visible: boolean;
	onClose: any;
	errorCode: string;
};

export const ErrorModal: FC<ErrorModalProps> = ({
	message,
	title,
	visible,
	onClose,
	errorCode,
}: ErrorModalProps) => {
	title = title || 'coupons.generic.title';
	message = message || 'coupons.generic.message';

	return (
		<CustomModal
			errorCode={errorCode}
			message={message}
			title={title}
			onClose={onClose}
			visibile={visible}
		/>
	);
};
