import React, { FC } from 'react';
import styles from './Error.module.css';
import logo from '../../assets/img/logo.png';
import { useTranslation } from 'react-i18next';

type ErrorPageProps = {
	title?: string;
	message: string;
	messageContent?: any;
	code?: string;
};

export const ErrorPage: FC<ErrorPageProps> = ({
	title,
	message,
	messageContent,
	code,
}: ErrorPageProps) => {
	const { t } = useTranslation();
	return (
		<div className={styles.errorPage}>
			<img className={styles.logo} alt="snapcart" src={logo} />
			<div className={styles.body}>
				<p className={styles.title}>{title ? t(title) : code || ''}</p>
				<p className={styles.message}>
					{messageContent ? messageContent : t(message || 'errors.SYSTEM')}
				</p>
				{code && <p>Error: ({code})</p>}
			</div>
		</div>
	);
};
