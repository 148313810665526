import React, { useEffect } from 'react';
import AppLoader from 'views/common/loader/AppLoader';
import { useSelector, useDispatch } from 'react-redux';
import { login } from '../store/auth';
import { useRouter } from 'hooks/router';
import { RootState } from '../types/store';
import { Redirect } from 'react-router-dom';
import { ErrorPage } from './common/Error';
import { ZendeskAPI } from 'react-zendesk';
import { useTranslation } from 'react-i18next';
import { bugsnag } from 'bugsnag';
import isEmpty from 'lodash/isEmpty';

export function Landing() {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const auth = useSelector((store: RootState) => Object.assign({}, store.auth));
	const { query } = useRouter();
	const { partner, token, redirectUrl, express } = query as {
		partner: string;
		token: string;
		redirectUrl: string;
		express: string;
	};

	// const copyToClipboard = str => {
	// 	const el = document.createElement('textarea');
	// 	el.value = str;
	// 	document.body.appendChild(el);
	// 	el.select();
	// 	document.execCommand('copy');
	// 	document.body.removeChild(el);
	// };

	// copyToClipboard(token);

	const openContactForm = () => {
		ZendeskAPI('webWidget', 'helpCenter:setSuggestions', {
			labels: ['receipt_sdk', 'coupon_sdk', 'legal_sdk'],
		});
		ZendeskAPI('webWidget', 'show');
		ZendeskAPI('webWidget', 'open');
	};

	const forbiddenContent = () => {
		return (
			<span>
				{t('errors.auth.forbidden.message')}{' '}
				<div className="link" onClick={openContactForm}>
					{t('errors.auth.forbidden.action')}
				</div>
			</span>
		);
	};

	const isExpress = express?.toLowerCase && express.toLowerCase() === 'true';
	const paramsProvided = !isEmpty(partner) && !isEmpty(token);
	useEffect(() => {
		if (!paramsProvided) {
			return;
		}
		dispatch(login(partner, token, redirectUrl, isExpress));
	}, [dispatch, partner, token, redirectUrl, isExpress, paramsProvided]);

	if (!paramsProvided) {
		return (
			<ErrorPage
				title="Bad request"
				message="Missing parameters, partner and token parameters are mandatory when accessing the app."
			></ErrorPage>
		);
	}

	if (auth.redirectTo) {
		bugsnag.setUser(auth.data.sub, auth.data.sub, auth.data.sub);
		ZendeskAPI('webWidget', 'prefill', { email: { value: auth.data?.sub } });
		return <Redirect to={auth.redirectTo} />;
	}

	if (auth.error) {
		if (auth.errorCode.toString() === '403') {
			auth.errorMessage = 'errors.auth.forbidden.message';
		}
		return (
			<ErrorPage
				messageContent={
					auth.errorCode.toString() === '403' ? forbiddenContent() : undefined
				}
				message={auth.errorMessage}
				code={auth.errorCode}
			/>
		);
	}

	return (
		<AppLoader isLoading={!auth.token}>
			<div className="App">
				<header className="App-header"></header>
			</div>
		</AppLoader>
	);
}
