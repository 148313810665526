import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { Landing } from 'views/Landing';
import React from 'react';
import { CouponsPage } from 'views/Coupons/Coupons';
import { CouponPage } from 'views/Coupon/Coupon';
import { Tips } from 'views/Snap/Tips';
import { Snap } from 'views/Snap/Snap';
import { Receipts } from 'views/Receipts/Receipts';
import { Receipt } from 'views/Receipt/Receipt';
import { Toast } from 'views/common/Toast';
import { Submitted } from 'views/Snap/Submitted';

function App() {
	(window as any).initialHistory = window.history.length;
	return (
		<ToastProvider placement="bottom-center" components={{ Toast: Toast }}>
			<Router>
				<Switch>
					<Route exact path="/">
						<Landing />
					</Route>
					<Route exact path="/health">
						<h3>All good!</h3>
					</Route>
					<Route exact path="/coupons">
						<CouponsPage />
					</Route>
					<Route exact path="/history">
						<Receipts />
					</Route>
					<Route exact path="/history/:id">
						<Receipt />
					</Route>
					<Route exact path="/coupon/:id">
						<CouponPage />
					</Route>
					<Route exact path="/snap">
						<Tips />
					</Route>
					<Route exact path="/upload">
						<Snap />
					</Route>
					<Route exact path="/submitted">
						<Submitted />
					</Route>
				</Switch>
			</Router>
		</ToastProvider>
	);
}

export default App;
