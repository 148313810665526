import React, { FC } from 'react';
import isNil from 'lodash/isNil';
import { PlusCircle, Check, Loader } from 'react-feather';
import styles from './Coupon.module.css';
import { Coupon as CouponType } from '../../types/coupon';
import { useDispatch, useSelector } from 'react-redux';
import { bookCoupon, clearBookingError } from 'store/coupons';
import { useRouter } from 'hooks/router';
import { CouponReward, RewardSize } from 'views/common/Reward';
import { useTranslation } from 'react-i18next';
import { CouponExpiry } from 'views/common/badges/CouponExpiry';
import { Count } from 'views/common/badges/Count';
import { HoursLeft } from 'views/common/badges/HoursLeft';
import { Hot } from 'views/common/badges/Hot';
import { RootState } from 'types/store';
import { ErrorModal } from 'views/common/ErrorModal';
import handleViewport from 'react-in-viewport';
import { EventsLogger } from 'views/common/EventsLogger';

type CouponProps = {
	coupon: CouponType;
	listName: string;
};

export const Coupon: FC<CouponProps> = ({ coupon, listName }: CouponProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const router = useRouter();

	const {
		loading = false,
		error = false,
		errorCode = '',
	}: {
		loading: boolean;
		error: boolean;
		errorCode: string;
	} = useSelector((store: RootState) =>
		Object.assign({}, store.coupons.bookings[coupon.id])
	);

	const navigate = () => {
		router.push(`/coupon/${coupon.id}`);
	};

	const onClose = e => {
		e.stopPropagation();
		dispatch(clearBookingError(coupon.id));
	};

	const CouponBlock = (props: { forwardedRef }) => {
		const { forwardedRef } = props;
		return (
			<div ref={forwardedRef} className={styles.coupon} onClick={navigate}>
				<ErrorModal
					onClose={onClose}
					title="errors.book.title"
					visible={error}
					errorCode={errorCode}
					message="errors.book.message"
				></ErrorModal>
				<div className={styles.imgContainer}>
					<Count count={coupon.remainingQuantity} />
					<img src={coupon.smallImage.thumbnail.url} alt="" />
					<CouponExpiry coupon={coupon} />
				</div>
				<div className={styles.mainInfo}>
					<div className={styles.price}>
						<CouponReward reward={coupon.reward} size={RewardSize.SMALL} />
					</div>
				</div>
				<div className={styles.name}>{coupon.name} </div>
				<div className={styles.conditions}>
					{coupon.maxCouponsPerUser && (
						<div>
							{t('coupon.limit')}{' '}
							{coupon.booking?.remainingQuantity || coupon.maxCouponsPerUser}
						</div>
					)}
					{coupon.minimumItems > 1 && (
						<div>
							{t('coupon.mustBuy')} {coupon.minimumItems}
						</div>
					)}
				</div>
				{coupon.featured && <Hot />}
			</div>
		);
	}

	const ViewportBlock = handleViewport(CouponBlock, { root: document.querySelector('#coupons-loader'), rootMargin: '-200px 0px -200px 0px' } /** options: {}, config: {} **/);
	return (
		<ViewportBlock onEnterViewport={() => EventsLogger.viewedCouponInList(coupon, listName)} />
	);
};
