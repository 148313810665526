import React, { FC } from 'react';
import classNames from 'classnames';
import { PurchaseDecision, PurchaseTag } from 'types/receipt';
import styles from './ReceiptSubmit.module.css';

import notHouseHold from '../../assets/img/icons/not_in_household.png';
import employer from '../../assets/img/icons/employer.png';
import uncleAunt from '../../assets/img/icons/uncleaunt.png';
import grandparents from '../../assets/img/icons/grandparents.png';
import children from '../../assets/img/icons/children.png';
import sibling from '../../assets/img/icons/siblings.png';
import parents from '../../assets/img/icons/parents.png';
import spouse from '../../assets/img/icons/spouse.png';
import myself from '../../assets/img/icons/myself.png';

import supermarket from '../../assets/img/icons/supermarket.png';
import restaurant from '../../assets/img/icons/restaurants.png';
import clothing from '../../assets/img/icons/clothing.png';
import transportation from '../../assets/img/icons/petrol_transportation.png';
import electronics from '../../assets/img/icons/electronics.png';
import other from '../../assets/img/icons/others.png';
import { useTranslation } from 'react-i18next';

export type IconTag = {
	name: PurchaseDecision | PurchaseTag;
	display: string;
	icon: string;
};

export const decisionMakers: IconTag[] = [
	{
		name: PurchaseDecision.NOT_HOUSEHOLD,
		display: 'Not in Household',
		icon: notHouseHold,
	},
	{ name: PurchaseDecision.EMPLOYER, display: 'Employer', icon: employer },
	{ name: PurchaseDecision.UNCLE_AUNT, display: 'Uncle/Aunt', icon: uncleAunt },
	{ name: PurchaseDecision.GRANDPARENTS, display: 'Grandparents', icon: grandparents },
	{ name: PurchaseDecision.CHILDREN, display: 'Children', icon: children },
	{ name: PurchaseDecision.SIBLING, display: 'Sibling', icon: sibling },
	{ name: PurchaseDecision.PARENTS, display: 'Parents', icon: parents },
	{ name: PurchaseDecision.SPOUSE, display: 'Spouse', icon: spouse },
	{ name: PurchaseDecision.MYSELF, display: 'Myself', icon: myself },
];

export const purchaseTag: IconTag[] = [
	{
		name: PurchaseTag.SUPERMARKET,
		display: 'Supermarket, Minimarket & Pharmacies',
		icon: supermarket,
	},
	{ name: PurchaseTag.RESTAURANTS, display: 'Restaurants', icon: restaurant },
	{ name: PurchaseTag.CLOTHING, display: 'Apparel/Clothing', icon: clothing },
	{
		name: PurchaseTag.TRANSPORTATION,
		display: 'Petrol & Trans portation',
		icon: transportation,
	},
	{ name: PurchaseTag.ELECTRONICS, display: 'Electronics', icon: electronics },
	{ name: PurchaseTag.OTHERS, display: 'Other', icon: other },
];

type IconProps = {
	tag: IconTag;
	onSelect: any;
	isActive: boolean;
};

export const Icon: FC<IconProps> = ({
	onSelect,
	isActive,
	tag: { display, icon, name },
}: IconProps) => {
	const { t } = useTranslation();
	return (
		<div
			className={classNames(styles.icon, { [styles.active]: isActive })}
			onClick={() => onSelect(name)}
		>
			<img src={icon} alt="" />
			<p>{t(name)}</p>
		</div>
	);
};
