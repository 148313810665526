import React, { FC, useEffect, useState } from 'react';
import styles from './ReceiptSubmit.module.css';
import isNil from 'lodash/isNil';
import classNames from 'classnames';
import { decisionMakers, purchaseTag, Icon } from './ReceiptIcons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'types/store';
import { sendReceipt } from 'store/receipts';
import { PurchaseDecision, PurchaseTag } from 'types/receipt';
import { useTranslation } from 'react-i18next';
import Loader from 'views/common/loader/Loader';
import { Submitted } from './Submitted';

export const ReceiptSubmit: FC = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	//const isLoading = useSelector((store: RootState) => store.receipts.loading);
	const images = useSelector((store: RootState) => store.receipts.images);
	const receipt = useSelector((store: RootState) => store.receipts.receiptInProgress);
	const sent = useSelector((store: RootState) => store.receipts.sent);
	const [selectedDecision, setDecision] = useState<PurchaseDecision | null>(
		PurchaseDecision.NOT_HOUSEHOLD
	);
	const [selectedpurchase, setPurchase] = useState<PurchaseTag | null>(
		PurchaseTag.SUPERMARKET
	);
	const disabledButton = isNil(selectedDecision) || isNil(selectedpurchase);

	const finalizeReceipt = () => {
		dispatch(
			sendReceipt(
				receipt.id,
				images,
				selectedDecision as PurchaseDecision,
				selectedpurchase as PurchaseTag
			)
		);
	};

	useEffect(() => {
		dispatch(
			sendReceipt(
				receipt.id,
				images,
				selectedDecision as PurchaseDecision,
				selectedpurchase as PurchaseTag
			)
		);
		// eslint-disable-next-line
	}, []);

	if (sent) {
		return <Submitted></Submitted>;
	}

	if (!sent) {
		return (
			<div className={styles.loading}>
				<Loader relative></Loader>
				<div className={styles.loadingMessage}>
					{t('Your receipt is being submitted')}
				</div>
			</div>
		);
	}

	return (
		<div className={styles.page}>
			<div className={styles.section}>
				<h3>{t('Who decides this purchase?')}</h3>
				<p>{t('Please pick one')}</p>
				<div className={styles.icons}>
					{decisionMakers.map(d => (
						<Icon
							key={d.name}
							isActive={selectedDecision === d.name}
							onSelect={name => setDecision(name)}
							tag={d}
						/>
					))}
				</div>
			</div>
			<div className={styles.section}>
				<h3>{t('What is the type of this receipt?')}</h3>
				<p>{t('Please pick one')}</p>
				<div className={styles.icons}>
					{purchaseTag.map(d => (
						<Icon
							key={d.name}
							isActive={selectedpurchase === d.name}
							onSelect={name => setPurchase(name)}
							tag={d}
						/>
					))}
				</div>
			</div>
			<div className={styles.action}>
				<div
					className={classNames(styles.btn, {
						[styles.disabled]: disabledButton,
					})}
					onClick={finalizeReceipt}
				>
					{t('SEND')}
				</div>
			</div>
		</div>
	);
};
