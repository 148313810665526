import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'types/store';
import { clearAutoBookError } from 'store/auth';
import { clearCouponsError } from 'store/coupons';
import { CustomModal } from 'views/common/modal/Modal';

export const CouponsErrorModal: FC = () => {
	const errorCode = useSelector((store: RootState) => store.auth.errorCode);
	const couponsLoadError = useSelector((store: RootState) => store.coupons.hasError);
	const couponsErrorCode = useSelector((store: RootState) => store.coupons.errorCode);
	const isCouponError = errorCode?.length > 0;
	const dispatch = useDispatch();
	const title = isCouponError ? 'sorry' : 'errors.coupons.title';
	const errorMessage = isCouponError ? errorCode : 'errors.coupons.message';

	const closeModal = () => {
		dispatch(clearAutoBookError());
		dispatch(clearCouponsError());
	};

	return (
		<CustomModal
			message={errorMessage}
			errorCode={couponsErrorCode}
			title={title}
			onClose={closeModal}
			visibile={isCouponError || couponsLoadError}
		/>
	);
};
