import React, { FC } from 'react';
import couponStyles from './Coupon.module.css';
import { useTranslation } from 'react-i18next';
import { Coupon } from 'types/coupon';
import isEmpty from 'lodash/isEmpty';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

type CouponDetailsProps = {
	coupon: Coupon;
};

export const CouponDetails: FC<CouponDetailsProps> = ({ coupon }: CouponDetailsProps) => {
	const { t } = useTranslation();
	const hasDetails = !isEmpty(coupon?.productDetails);
	const hasEarningInfo = !isEmpty(coupon?.howToEarn);

	const getHowToEarn = (showHeader = false) => {
		return (
			<div className={couponStyles.howTo}>
				{showHeader ? <h3>{t('coupon.details.howToEarn')}</h3> : ''}
				{hasEarningInfo ? (
					<div dangerouslySetInnerHTML={{ __html: coupon?.howToEarn }} />
				) : (
					<span>
						<p>{t('coupon.details.howToEarn1')}</p>
						<p>{t('coupon.details.howToEarn2')}</p>
						<p>{t('coupon.details.howToEarn3')}</p>
						<p>{t('coupon.details.howToEarn4')}</p>
					</span>
				)}
			</div>
		);
	};

	const getTabs = () => {
		return (
			<Tabs>
				<TabList>
					<Tab>{t('coupon.details.howToEarn')}</Tab>
					<Tab>{t('coupon.details.productDetails')}</Tab>
				</TabList>

				<TabPanel>{getHowToEarn()}</TabPanel>
				<TabPanel>
					<div
						className={couponStyles.howTo}
						dangerouslySetInnerHTML={{ __html: coupon.productDetails }}
					/>
				</TabPanel>
			</Tabs>
		);
	};

	return <div>{hasDetails ? getTabs() : getHowToEarn(true)}</div>;
};
