import React, { FC } from 'react';
import { Tip } from './Tip';
import styles from './MultiReceiptTip.module.css';
import receipt from '../../assets/img/short_receipt.png';

export const MultiReceiptTip: FC<{ isActive: boolean }> = ({
	isActive,
}: {
	isActive: boolean;
}) => {
	return (
		<Tip
			primaryTip="tips.multi.primaryTip"
			secondaryTip="tips.multi.secondTip"
			thirdTip="tips.multi.thirdTip"
		>
			<div className={styles.images}>
				{isActive && (
					<img className={styles.receipt} src={receipt} alt="receipt" />
				)}
			</div>
		</Tip>
	);
};
