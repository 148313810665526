export enum CouponsSort {
	NAME = 'name',
	START_DATE = 'start_date',
	END_DATE = 'end_date',
}

export enum BookinsSort {
	NAME = 'coupon.name',
	BOOKING_DATE = 'booked_at',
}

export enum SortType {
	ASC = 'asc',
	DESC = 'desc',
}
