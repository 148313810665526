import React from 'react';
import ReactDOM from 'react-dom';
import firebase from 'firebase';
import { Provider } from 'react-redux';
import store from './store/store';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { initi18n } from 'translations/localization';
import { saveLang, getLang } from 'api';
import zendeskConfig from 'zendesk.config';
import Zendesk, { ZendeskAPI } from 'react-zendesk';
import { bugsnag } from 'bugsnag';
import config from 'config';

const urlParams = new URLSearchParams(window.location.search);
const lang = urlParams.get('lang');
initi18n(lang || getLang());
saveLang(lang);

firebase.initializeApp(config.fireBaseConfig);
firebase.analytics();
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const ErrorBoundary = bugsnag.getPlugin('react')!.createErrorBoundary(React);

ReactDOM.render(
	<ErrorBoundary>
		<Provider store={store}>
			<App />
			<Zendesk
				zendeskKey={config.zendeskKey as string}
				{...zendeskConfig}
				onLoaded={() => {
					ZendeskAPI('webWidget', 'setLocale', getLang());
					ZendeskAPI('webWidget', 'hide');
					ZendeskAPI('webWidget:on', 'close', () => {
						ZendeskAPI('webWidget', 'hide');
					});
				}}
			/>
		</Provider>
	</ErrorBoundary>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
