import React, { FC, ReactElement } from 'react';
import classnames from 'classnames';
import styles from './/Layout.module.css';
import { ArrowLeft, HelpCircle } from 'react-feather';
import { useRouter } from 'hooks/router';
import { useTranslation } from 'react-i18next';
import isNil from 'lodash/isNil';
import { ZendeskAPI } from 'react-zendesk';
import { useSelector } from 'react-redux';
import { RootState } from 'types/store';

type LayoutProps = {
	title: string;
	header?: ReactElement;
	children: ReactElement;
	hideBackButton?: boolean;
	scrolling?: boolean;
};

export const Layout: FC<LayoutProps> = ({
	title,
	header,
	children,
	hideBackButton,
	scrolling = true,
}: LayoutProps) => {
	const router = useRouter();
	const { t } = useTranslation();
	const initialHistoryLength = (window as any).initialHistory;
	const currentHistoryLength = router.history.length;
	const canGoBack = currentHistoryLength > initialHistoryLength;
	const isExpress = useSelector((store: RootState) => store.auth.express);

	const goBack = () => {
		if (!canGoBack) {
			router.history.replace('/coupons');
			return;
		}

		router.history.goBack();
	};

	// hide the back button if its express mode and there nothing to go back to
	hideBackButton = hideBackButton || (isExpress && !canGoBack);

	return (
		<div className={styles.layout}>
			<div className={styles.header}>
				{!hideBackButton && (
					<ArrowLeft className={styles.headerButton} onClick={goBack} />
				)}
				{isNil(header) ? <div className={styles.title}>{t(title)}</div> : header}
				<HelpCircle
					className={styles.headerButton}
					onClick={() => {
						ZendeskAPI('webWidget', 'helpCenter:setSuggestions', {
							labels: ['receipt_sdk', 'coupon_sdk', 'legal_sdk'],
						});
						ZendeskAPI('webWidget', 'show');
						ZendeskAPI('webWidget', 'open');
					}}
				/>
			</div>
			<div
				id="layout-body"
				className={classnames(styles.children, { [styles.scrolling]: scrolling })}
			>
				{children}
			</div>
		</div>
	);
};
