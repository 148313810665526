import styles from './Loader.module.css';
import classnames from 'classnames';
import React, { FC } from 'react';
/**
 * A component used to display a loading while some processes are loading.
 */
type LoaderPops = {
	isLoading?: boolean;
	relative?: boolean;
};

const Loader: FC<LoaderPops> = ({ isLoading = true, relative = false }: LoaderPops) => {
	if (!isLoading) {
		return <React.Fragment></React.Fragment>;
	}

	return (
		<div className={classnames(styles.loader, { [styles.relative]: relative })}>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	);
};

export default Loader;
