import { Coupon } from './coupon';

export enum PurchaseTag {
	SUPERMARKET = 'supermarket_minimarket_pharmacies',
	RESTAURANTS = 'restaurants',
	CLOTHING = 'apparel_clothing',
	TRANSPORTATION = 'petrol_transportation',
	ELECTRONICS = 'electronics',
	OTHERS = 'others',
	ECOMMERCE = 'ecommerce',
	UNKOWN = 'unknown',
}

export enum PurchaseDecision {
	NOT_HOUSEHOLD = 'not_in_household',
	EMPLOYER = 'employer',
	UNCLE_AUNT = 'uncle_aunt',
	GRANDPARENTS = 'grandparents',
	CHILDREN = 'children',
	SIBLING = 'sibling',
	PARENTS = 'parents',
	SPOUSE = 'spouse',
	MYSELF = 'myself',
}

export enum ReceiptStatus {
	SENT = 'sent',
	REJECTED = 'rejected',
	COMPLETED = 'completed',
}

export enum ReceiptPageType {
	LATEST = 'latest',
	COMPLETED = 'completed',
	REJECTED = 'rejected',
	SENT = 'sent',
}

export type ReceiptStatistics = {
	[ReceiptStatus.COMPLETED]: number;
	[ReceiptStatus.REJECTED]: number;
	[ReceiptStatus.SENT]: number;
};

export enum RewardSourceType {
	TYPE_USER_ACTIVATION = 'TYPE_USER_ACTIVATION',
	TYPE_SNAPTASTIC = 'TYPE_SNAPTASTIC',
	TYPE_POLL = 'TYPE_POLL',
	TYPE_BONUS = 'TYPE_BONUS',
	TYPE_INITIAL_REWARD = 'TYPE_INITIAL_REWARD',
	TYPE_CASHBACK_RECEIPT = 'TYPE_CASHBACK_RECEIPT',
	TYPE_COUPON = 'TYPE_COUPON',
	TYPE_CASHOUT = 'TYPE_CASHOUT',
	TYPE_SNAPTASTIC_FEE = 'TYPE_SNAPTASTIC_FEE',
	TYPE_REWARDED_VIDEO_AD = 'TYPE_REWARDED_VIDEO_AD',
	TYPE_REWARD_ADJOE = 'TYPE_REWARD_ADJOE',
	TYPE_REWARD_GMO = 'TYPE_REWARD_GMO',
}

export type Receipt = {
	id: number;
	receiptId: string;
	type: string;
	status: ReceiptStatus;
	totalImages: number;
	images: {
		medium: {
			id: number;
			url: string;
		};
	}[];
	totalRewards: { currency: number; total: number; sourceType: RewardSourceType }[];
	allowComplain: boolean;
	totalValue: number;
	purchaseTag: string;
	rejectReason: string;
	coupons: Coupon[];
	sentAt: Date;
	purchaseTime: Date;
	branch: { name: string };
};
