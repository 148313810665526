const idTranslations = {
	Latest: 'Terakhir',
	Completed: 'Selesai',
	Rejected: 'Ditolak',
	Sent: 'Terkirim',
	Completed_no_coupons: 'Selesai, namun tidak ada hadiah coupon yang didapatkan',
	completed_no_coupons: 'Selesai, namun tidak ada hadiah coupon yang didapatkan',
	completed: 'Selesai',
	rejected: 'Ditolak',
	sent: 'Terkirim',
	pending: 'Tertunda',
	processing: 'Diproses',
	'I need help with this receipt': 'SAYA BUTUH BANTUAN UNTUK STRUK INI',
	Status: 'Status',
	Tag: 'Label',
	Type: 'Tipe',
	Reason: 'Alasan',
	History: 'Struk',

	SKIP: 'LEWATI',

	supermarket_minimarket_pharmacies: 'Supermarket, Minimarket, Apotek',
	restaurants: 'Restoran',
	apparel_clothing: 'Fashion',
	petrol_transportation: 'Bahan bakar, Transportasi',
	electronics: 'Elektronik',
	ecommerce: 'Transaksi online',
	others: 'Lainnya',
	unknown: 'Tidak diketahui',
	not_in_household: 'Tidak tinggal bersama saya',
	employer: 'Atasan',
	uncle_aunt: 'Paman/Bibi',
	grandparents: 'Kakek/Nenek',
	children: 'Anak',
	sibling: 'Saudara',
	parents: 'Orang tua',
	spouse: 'Pasangan',
	myself: 'Diri saya sendiri',

	'Your receipt is being submitted': 'Struk kamu sedang dikirim',
	'Thank you, your receipt is submitted': 'Terima kasih, struk telah berhasil dikirim',
	'Your receipt is being processed': 'Struk kamu sedang diproses',

	'Store Name': 'Nama Toko',
	Date: 'Tanggal',
	'Total value': 'Total pengeluaran',
	Rewards: 'Hadiah',
	'Coupon(s) Redeemed': 'Coupon yang Ditebus',
	'Coupon Redeemed Value': 'Nilai Coupon yang Ditebus',
	'Purchase time': 'Waktu beli',

	'Who decides this purchase?': 'Siapa yang memutuskan pembelian ini?',
	'What is the type of this receipt?': 'Apa jenis struk ini?',
	'Please pick one': 'Mohon pilih salah satu',
	SEND: 'KIRIM',
	RETAKE: 'ULANG',
	FINISH: 'SELESAI',
	snap: {
		submit: {
			success: {
				title: 'Terima kasih telah mengirimkan struk Anda',
				message:
					'Apabila struk Anda memiliki produk yang valid, Anda akan segera menerima cashback Anda. Terus snap struk & terus dapatkan cashback',
				gojek: {
					title: 'Terima kasih telah mengirimkan struk Anda',
					message:
						'Apabila struk Anda memiliki produk yang valid, Anda akan menerima cashback dalam 7 hari kedepan. Terus snap struk & terus dapatkan cashback',
				},
			},
		},
	},
	tips: {
		multi: {
			primaryTip: 'Satu Struk, Satu Kali Foto',
			secondTip: 'Punya banyak struk? Hebat!',
			thirdTip: 'Mohon foto satu-persatu.',
		},
		light: {
			primaryTip: 'Pastikan Struk Dapat Terbaca Jelas',
			secondTip: 'Foto pada tempat terang.',
			thirdTip: 'Struk juga tidak boleh lecek ataupun robek.',
		},
		receipt: {
			primaryTip: 'Snap Keseluruhan Struk dalam Posisi Portrait',
			secondTip:
				'Pastikan kamu memfoto struk dalam posisi portrait (memanjang ke atas) & keseluruhan struk terlihat dalam foto. Gunakan tombol (+) untuk struk panjang.',
		},
		accept: {
			1: {
				primaryTip: 'Struk seperti apa yang diterima?',
				secondTip: 'Pastikan struk Anda sesuai dengan aturan.',
				1: 'Struk belanja apa saja dapat diterima, asalkan tercantum nama dan alamat toko, nama dan harga produk, jumlah produk, dan total harga.',
				2: 'Waktu transaksi pada struk paling lama 3 hari lalu.',
				3: 'Pembelian untuk kebutuhan pribadi, dari orang yang tinggal serumah. Bukan untuk dijual kembali.',
				4: 'Struk asli. Bukan duplikat.',
			},
			2: {
				primaryTip: 'Struk seperti apa yang diterima?',
				secondTip: 'Pastikan struk Anda sesuai dengan aturan.',
				1: 'Struk dicetak, bukan hasil tulisan tangan.',
				2: 'Hanya foto struk yang berasal dari negara tempat tinggal Anda.',
				3: 'Jangan memisahkan barang pembelian Anda dengan tujuan untuk mendapatkan beberapa struk.	 ',
				4: 'Struk belanja harus berukuran struk belanja pada umumnya (bukan A4, bukan invoice) ',
			},
		},
	},
	coupon: {
		coupon: 'Coupon',
		couponDetails: 'Rincian Coupon',
		couponTraction: 'Pesan Coupon untuk langsung menerima reward saat kamu Snap!',
		limit: 'Limit',
		mustBuy: 'Harus Beli',
		expiryDays: 'Hangus dalam {{days}} hari',
		expiryHours: 'Hangus dalam {{hours}} jam',
		countLeft: 'Sisa < {{count}}',
		countZero: 'Coupon Habis',
		available: {
			available: 'TERSEDIA',
			emptyTitle:
				'Aduh, mohon maaf. Coupon yang seharusnya tersedia sedang keluar sebentar.',
			emptyMessage: 'Harap kembali sebentar lagi ya.',
		},
		expiring: {
			expiring: 'AKAN HANGUS',
			emptyTitle: 'Tidak ada Coupon yang akan kadaluarsa.',
			emptyMessage:
				'Tapi kalau kamu tidak booking dari sekarang, hati-hati kehabisan!',
		},
		booked: {
			booked: 'DIPESAN',
			emptyTitle:
				'Psst! Kamu bisa dapat Cashback saat berbelanja lho. Mangkanya, ayo buruan ambil Couponnya!',
			emptyMessage:
				'Ayo ambil Couponmu sekarang untuk menerima Cashback saat berbelanja. Coupon apa saja kah yang tersedia?',
		},
		reward: {
			perItem: 'Per-produk',
		},
		receipt: {
			Type: 'Tipe',
			Status: 'Status',
			Tag: 'Label',
		},
		details: {
			details: 'Rincian',
			bookingExpiresIn: 'Pesanan akan hangus dalam',
			noMinPurchase: '- Tidak ada jumlah belanja minimum',
			minPurchase: '- Kamu perlu belanja minimum {{count}} produk dalam 1 struk',
			noMaxPurchase: '- Tidak ada batas maksimum',
			maxPurchase:
				'- Hanya berlaku untuk maksimum {{count}} produk untuk setiap pengguna',
			allChains: '- Promo ini berlaku di semua toko',
			includeChains: '- Promo ini hanya berlaku di toko {{chains}}',
			excludeChains: '- Promo ini tidak berlaku di toko {{chains}}',
			bookingDetail:
				'- Coupon yang kamu pesan hanya akan bertahan 24 jam di halaman ‘Dipesan‘. Hal itu tetap berlaku walaupun Coupon akan hangus dalam waktu kurang dari 24 jam. Kamu bisa memesan ulang Coupon di halaman ‘Tersedia‘ / ‘Akan Hangus‘',
			howToEarn: 'Cara mendapatkan',
			howToEarn1: '1. Beli produk sesuai dengan ketentuan Coupon',
			howToEarn2:
				'2. Foto dan upload struknya dengan menekan tombol Snap',
			howToEarn3: '3. Pastikan seluruh bagian struk kamu terfoto dengan jelas',
			howToEarn4: '4. Rewards akan dikirimkan ke akunmu',
			productDetails: 'Detail Produk',
		},
		sorting: {
			sorting: 'Urutkan Coupon',
			brandAsc: 'Nama Brand A-Z',
			brandDesc: 'Nama Brand Z-A',
			dateAsc: 'Paling Awal - Terbaru',
			dateDesc: 'Terbaru - Paling Awal',
			expiryAsc: 'Segera - Paling Lama',
			expiryDesc: 'Paling Lama - Segera',
			brandAscTxt: 'Brand Name A-Z',
			brandDescTxt: 'Brand Name Z-A',
			startAscTxt: 'Tanggal Terbit Paling Awal Ke Terbaru',
			startDescTxt: 'Tanggal Terbit Terbaru Ke Paling Awal',
			bookAscTxt: 'Dipesan Paling Awal Ke Terbaru',
			bookDescTxt: 'Dipesan Terbaru Ke Paling Awal',
			expiryAscTxt: 'Segera Hangus Ke Paling Lama Hangus',
			expiryDescTxt: 'Paling Lama Hangus Ke Segera Hangus',
		},
	},
	express: {
		error: {
			coupon: {
				expired: 'Masa promosi Coupon telah berakhir',
				quantity_exceeded: 'Kamu sudah mencapai batas maksimal untuk Coupon ini.',
				not_found: ' Coupon sudah habis.',
				unknown: 'Mohon maaf atas ketidaknyamanannya. Silahkan coba lagi nanti',
				internal: 'Mohon maaf atas ketidaknyamanannya. Silahkan coba lagi nanti',
				null: 'Mohon maaf atas ketidaknyamanannya. Silahkan coba lagi nanti',
			},
		},
	},
	sorry: 'Maaf',
	error: {
		coupon: {
			expired:
				'Masa promosi Coupon telah berakhir. Ayo cek Coupon lain yang tersedia untuk kamu!',
			quantity_exceeded:
				'Kamu sudah mencapai batas maksimal untuk Coupon ini. Ayo cek Coupon lain yang tersedia untuk kamu!',
			not_found:
				'Coupon sudah habis. Ayo cek Coupon lain yang tersedia untuk kamu!',
			unknown: 'Mohon maaf atas ketidaknyamanannya. Silahkan coba lagi nanti',
			internal: 'Mohon maaf atas ketidaknyamanannya. Silahkan coba lagi nanti',
			null: 'Mohon maaf atas ketidaknyamanannya. Silahkan coba lagi nanti',
		},
	},
	errors: {
		SYSTEM: 'The system is unavailable at the moment',
		PROMO_END: 'Masa promosi Coupon telah berakhir',
		COUPON_UNAVAILABLE: 'Coupon sudah habis',
		COUPON_LIMIT: 'Kamu sudah mencapai batas maksimal untuk Coupon ini',
		COUPONS_ERROR: {
			title: 'Unable to load Coupons.',
			message: 'Mohon maaf atas ketidaknyamanannya. Silahkan coba lagi nanti',
		},
		coupons: {
			title: 'Unable to load Coupons',
			message: 'Mohon maaf atas ketidaknyamanannya. Silahkan coba lagi nanti',
		},
		coupon: {
			title: 'Unable to load Coupon',
			message: 'Mohon maaf atas ketidaknyamanannya. Silahkan coba lagi nanti',
		},
		book: {
			title: 'Unable to book Coupon',
			message: 'Mohon maaf atas ketidaknyamanannya. Silahkan coba lagi nanti',
		},
		receipt: {
			title: 'Unable to load receipt detail',
			message: 'Mohon maaf atas ketidaknyamanannya. Silahkan coba lagi nanti',
		},
		receipts: {
			title: 'Unable to load receipts',
			message: 'Mohon maaf atas ketidaknyamanannya. Silahkan coba lagi nanti',
			create: {
				title: 'Unable to create receipt',
				message: 'Mohon maaf atas ketidaknyamanannya. Silahkan coba lagi nanti',
			},
			send: {
				title: 'Unable to send receipt',
				message: 'Mohon maaf atas ketidaknyamanannya. Silahkan coba lagi nanti',
			},
			no_coupons_available: {
				title: 'Unable to create receipt',
				message: 'Belum ada coupon saat ini.',
			},
		},
		generic: {
			title: 'The system is unavailable at the moment',
			message: 'Mohon maaf atas ketidaknyamanannya. Silahkan coba lagi nanti',
		},
		auth: {
			forbidden: {
				message:
					'Maaf, akun Anda tidak dapat membuka Coupon. Untuk keterangan lebih lanjut, silahkan menghubungi Customer Service ',
				action: 'disini.',
			},
		},
	},
};

export default idTranslations;
