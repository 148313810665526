import React, { FC } from 'react';
import { Tip } from './Tip';
import styles from './AcceptTip.module.css';
import accept1_1 from '../../assets/img/accept_1_1.png';
import accept1_2 from '../../assets/img/accept_1_2.png';
import accept1_3 from '../../assets/img/accept_1_3.png';
import accept1_4 from '../../assets/img/accept_1_4.png';

import accept2_1 from '../../assets/img/accept_2_1.png';
import accept2_2 from '../../assets/img/accept_2_2.png';
import accept2_3 from '../../assets/img/accept_2_3.png';
import accept2_4 from '../../assets/img/accept_2_4.png';
import { useTranslation } from 'react-i18next';

export const AcceptTip: FC<{ isActive: boolean; index: number }> = ({
	isActive,
	index,
}: {
	isActive: boolean;
	index: number;
}) => {
	const { t } = useTranslation();

	return (
		<Tip
			primaryTip={`tips.accept.${index}.primaryTip`}
			secondaryTip={`tips.accept.${index}.secondTip`}
		>
			<div className={styles.images}>
				{isActive && (
					<div className={styles.tips}>
						<div>
							<img
								alt="Accepted Receipt Rule"
								src={index === 1 ? accept1_1 : accept2_1}
							/>
							<p>{t(`tips.accept.${index}.${1}`)}</p>
						</div>
						<div>
							<img
								alt="Accepted Receipt Rule"
								src={index === 1 ? accept1_2 : accept2_2}
							/>
							<p>{t(`tips.accept.${index}.${2}`)}</p>
						</div>
						<div>
							<img
								alt="Accepted Receipt Rule"
								src={index === 1 ? accept1_3 : accept2_3}
							/>
							<p>{t(`tips.accept.${index}.${3}`)}</p>
						</div>
						<div>
							<img
								alt="Accepted Receipt Rule"
								src={index === 1 ? accept1_4 : accept2_4}
							/>
							<p>{t(`tips.accept.${index}.${4}`)}</p>
						</div>
					</div>
				)}
			</div>
		</Tip>
	);
};
