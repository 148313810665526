import React, { FC } from 'react';
import { CouponsTypeState } from '../../types/store';
import styles from './Coupons.module.css';
import { Coupon } from './Coupon';
import notAvailable from '../../assets/img/not_available.png';
import { CouponsPageType } from 'types/coupon';
import { CouponPlaceholder } from './CouponPlaceholder';
import { useTranslation } from 'react-i18next';
import VisibilitySensor from 'react-visibility-sensor';
import { RefreshCcw, ArrowDown } from 'react-feather';
import { PullToRefresh } from 'react-js-pull-to-refresh';

type CouponsLoaderProps = {
	name: string;
	couponsState: CouponsTypeState;
	loadMore: any;
	refresh: any;
	pageType: CouponsPageType;
};

export const CouponsLoader: FC<CouponsLoaderProps> = ({
	loadMore,
	refresh,
	pageType,
	couponsState: { isLoading, coupons },
}: CouponsLoaderProps) => {
	const loadingCoupons = new Array(10).fill(0);
	const { t } = useTranslation();

	if (!isLoading && coupons.length < 1) {
		return (
			<div className={styles.empty}>
				<img className={styles.emptyImg} src={notAvailable} alt="not-available" />
				<p className={styles.emptyTitle}>{t(`coupon.${pageType}.emptyTitle`)}</p>
				<p className={styles.emptyMessage}>
					{t(`coupon.${pageType}.emptyMessage`)}
				</p>
			</div>
		);
	}

	const onLoadMore = (isVibisible: boolean) => {
		if (isVibisible) {
			loadMore();
		}
	};

	const handleRefresh = () => {
		refresh();
		return Promise.resolve();
	};

	return (
		<div>
			<PullToRefresh
				pullDownContent={
					<div style={{ textAlign: 'center', height: 50 }}>
						<ArrowDown color="#4db4dc" />
					</div>
				}
				releaseContent={
					<div style={{ textAlign: 'center', height: 50 }}>
						<RefreshCcw color="#4db4dc" />
					</div>
				}
				refreshContent={<div></div>}
				pullDownThreshold={75}
				onRefresh={handleRefresh}
				triggerHeight={100}
				backgroundColor="#f2f2f2"
				startInvisible={true}
			>
				<div className={styles.coupons}>
					{coupons.map((c, i) => (
						<Coupon listName={pageType} key={`available_${c.id}-i`} coupon={c} />
					))}
					{isLoading &&
						loadingCoupons.map((e, i) => {
							return <CouponPlaceholder key={i} />;
						})}
				</div>
				<VisibilitySensor onChange={onLoadMore}>
					<div style={{ height: 5 }}></div>
				</VisibilitySensor>
			</PullToRefresh>
		</div>
	);
};
