import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en';
import id from './id';
import config from '../config';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
	en: {
		translation: en,
	},
	id: {
		translation: id,
	},
};

export const initi18n = locale => {
	i18n.use(initReactI18next).init({
		resources,
		fallbackLng: config.defaultLanguage,
		lng: locale,
		interpolation: {
			escapeValue: false,
		},
	});
};
