import { createSlice } from '@reduxjs/toolkit';
import { EventsLogger } from 'views/common/EventsLogger';
import { getCurrencies } from '../api/index';
// Slice
const slice = createSlice({
	name: 'currencies',
	initialState: [],
	reducers: {
		fetchSuccess: (state, action) => {
			return (state = action.payload);
		},
	},
});

export default slice.reducer;

// Actions
const { fetchSuccess } = slice.actions;
export const fetchCurrencies = () => async dispatch => {
	try {
		const data = await getCurrencies();
		dispatch(fetchSuccess(data));
	} catch (e) {
		EventsLogger.exception('Failed to fetch currencies', { error: e.response });
		return console.error(e.message);
	}
};
